import React from 'react';
import './HomeScreenPointers.css'; // This is your CSS file for the component

// Import your GIF
import readersArrowGif from '../../assets/images/hopefularrow.png'; 

function HomeScreenPointers() {
  return (
    <>
        <div className="readers-section">
            <img src={readersArrowGif} alt="Readers Arrow" className="readers-arrow" />
            <span className="readers-text">Readers</span>
        </div>

        <div className="writers-section">
            <div className="written-stuff">
                <div className="writers-text">Writers</div>
                <div className="writers-subtext">FYI: there's a dresscode</div>
            </div>
            <img src={readersArrowGif} alt="Writers Arrow" className="writers-arrow" />
            
        </div>

    </>
    
  );
}

export default HomeScreenPointers;
