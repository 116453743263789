import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../utils/axiosInstance';
import { fetchAuthSession } from 'aws-amplify/auth';

// Updated async thunk for fetching home readers
export const fetchHomeReaders = createAsyncThunk(
    'homeReaders/fetchHomeReaders',
    async ({ user_id, writer_name }, { rejectWithValue }) => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens.idToken.toString();

        console.log('wrirter_name:', writer_name);
  
        const response = await apiInstance.get(`/clicks/readersforwriters`, {
            params: {
              user_id,
              writer_name
            },
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
        console.log('Home readers data:', response.data); 

        return response.data;
      } catch (error) {
        console.error('Failed to fetch home readers:', error.response?.data);
        return rejectWithValue(error.response?.data || 'An error occurred');
      }
    }
);

const homeReadersSlice = createSlice({
  name: 'homeReaders',
  initialState: {
    readers: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetHomeReaders: (state) => {
      console.log("Resetting home readers");
      state.readers = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeReaders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHomeReaders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.readers = action.payload;
      })
      .addCase(fetchHomeReaders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to fetch home readers';
      });
  },
});

export const { resetHomeReaders } = homeReadersSlice.actions;

export default homeReadersSlice.reducer;