import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentUser, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { setIsAuthenticated, setUserEmail,setUserId,setUserRole, setUserName } from './userSlice';

export const checkEmployeeStatus = createAsyncThunk(
  'employee/checkEmployeeStatus',
  async (_, { dispatch }) => {
    try {
      const currentUser = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      console.log('heck yeah', userAttributes);
      const { idToken } = await fetchAuthSession();

      const userRole = userAttributes['custom:userRole'];
      const employeeRole = userAttributes['custom:employeeRole'];
      const usersName = userAttributes.name;
      const userId = currentUser.userId;

      dispatch(setUserId(userId));
      dispatch(setIsAuthenticated(true));
      dispatch(setEmployeeRole(employeeRole));
      dispatch(setUserRole(userRole));
      dispatch(setUserEmail(userAttributes.email));
      dispatch(setUserName(usersName));

      
    } catch (error) {
      console.log('Error in checkEmployeeStatus:', error);
      dispatch(setUserRole(null));
      dispatch(setUserEmail(null));
      dispatch(setUserName(null));
      dispatch(setUserId(null));
      dispatch(setIsAuthenticated(false));
      dispatch(setEmployeeRole(null));
    }
  }
);

export const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
  
    employeeRole: null,
  },
  reducers: {
    setEmployeeRole: (state, action) => {
      state.employeeRole = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkEmployeeStatus.fulfilled, (state) => {
      // Handle fulfilled state if necessary
    });
  },
});

export const { setEmployeeRole } = employeeSlice.actions;

export default employeeSlice.reducer;
