import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { signOut } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
import './PendingWriterComponent.css';
import {apiInstance} from '../../utils/axiosInstance';


const PendingWriterComponent = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.userId);

  const handleCheckStatus = async () => {
    try {
      const currentTime = new Date().getTime();
      const applicationLastCheckTime = Number(localStorage.getItem('applicationLastCheckTime')) || 0;

      if (applicationLastCheckTime && currentTime - applicationLastCheckTime < 30 * 1000) {
        window.alert("Whoa, hold your horses, our people don't work that hard, check back in a couple of hours.");
        return;
      }

      const session = await fetchAuthSession();
      const jwtToken = session.tokens.idToken.toString();

      const response = await apiInstance.get('/applications/status', {
        params: {
          userId: userId, // Now passing userId in the request
        },
        headers: { 'Authorization': `Bearer ${jwtToken}` },
      });

      if (response.data.status === 'accepted') {
        window.alert("You're in. Go get-a-writing.");
        dispatch(checkAuthenticationStatus()); // Trigger a re-check of authentication status to update role
      } else if (response.data.status === 'applied') {
        window.alert("Nothing has changed, application still pending. The lazy bastards are being lazy again.");
      } else {
        window.alert("Sorry dude or dudette. We denied the application. You see, we're going for a dress code. Show off your chaps somewhere and apply again in a month.");
        window.location.reload();
      }

      localStorage.setItem('applicationLastCheckTime', currentTime.toString());
    } catch (error) {
      console.error('Error checking application status:', error);
      window.alert("Oh no, something on our backend went wrong. Email turbo@shorterstories.xyz if you're worried about anything.");
    }
  };
  

  const handleSignOut = async () => {
    try {
        await signOut();
        dispatch(checkAuthenticationStatus());
    } catch (error) {
        console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="pendingWriterComponent">
      <div className="messageBox">
        <h2 className="bold"> Application pending</h2>
        <p>Your application is currently under review. We will get back to you shortly. You can click below to check the latest. </p>
        <p>Feel free to email turbo@shorterstories.xyz if you want to get in touch. Maybe you'd like to ask a question, rant about the state of the world or maybe you just want to tell us we are a bunch of jerks. Whatever you're feeling.</p>
      </div>
      <button onClick={handleCheckStatus} className="checkStatusButton">Check updated status</button>
      <div className="separator"></div>
      <button onClick={handleSignOut} className="signOutButton">Sign Out</button>
    </div>
  );
};

export default PendingWriterComponent;
