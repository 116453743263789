
import React, { useState } from 'react';
import './EditHeadshotModalContent.css';


import printingPressProfile from '../../assets/images/printingpressprofile-full.png';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenericModal } from '../../store/modalSlice';
import { setEditableAuthorsData, setProfileImageFile } from '../../store/editWriterSlice';
import imageCompression from 'browser-image-compression';

const EditHeadshotModalContent = () => {
  const dispatch = useDispatch();
  const authorData = useSelector((state) => state.editWriter.editableAuthorsData);
  const [isImageTouched, setIsImageTouched] = useState(false);
  const { profile_image_jpeg, profile_image_webp, ...restOfAuthorData } = authorData;

  const [selectedImage, setSelectedImage] = useState(authorData.profile_image || authorData.profile_image_webp || printingPressProfile);

  

  const fileInputRef = React.createRef();

  const [previousImage, setPreviousImage] = useState(authorData.profile_image_webp || printingPressProfile);
  const originalProfileImageFile = useSelector((state) => state.editWriter.profileImageFile);


  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Compress and correct orientation
        const options = {
          maxSizeMB: 1,
          useWebWorker: true,
          alwaysKeepResolution: true,
        };
        const compressedFile = await imageCompression(file, options);
        const base64 = await imageCompression.getDataUrlFromFile(compressedFile);
  
        // Set the corrected base64 image
        setSelectedImage(base64);
        dispatch(setProfileImageFile(base64));
        setIsImageTouched(true);
      } catch (error) {
        console.error("Error compressing and correcting image:", error);
      }
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const revertToPreviousImage = () => {
    setSelectedImage(previousImage);
    setIsImageTouched(false); 
    dispatch(setProfileImageFile(originalProfileImageFile));
  };


  const revertToDefaultImage = () => {
    // Logic to revert to the default image
    setSelectedImage(printingPressProfile);
    setIsImageTouched(true); 
    dispatch(setProfileImageFile(null));

  };

  const handleUpdateAuthorData = () => {

    if (isImageTouched) {
      const updatedAuthorData = {
        ...restOfAuthorData,
        profile_image: selectedImage,
      };
      dispatch(setEditableAuthorsData(updatedAuthorData));
    }
    dispatch(hideGenericModal());
    // Here you could also close the modal or show a success message
  };

  return (
    <div className="modal">
      <div className="contentBox">

        <div className="titleContainer">
          <p className="modalTitle">Upload portrait:</p>
        </div>
        {selectedImage && <img src={selectedImage} alt="Selected" className="image-preview" />}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
          className="fileInput"
          accept="image/*"
          style={{ display: 'none' }} // Hide the actual file input
        />

        <button onClick={handleUploadClick} className='infosButton'>Upload image</button>
        <div className="textButtonContainer">
          <button onClick={revertToPreviousImage} className="textButton">Revert to previous image</button>
          <button onClick={revertToDefaultImage} className="textButton">Revert to default image</button>
        </div>
      </div>
      <button onClick={handleUpdateAuthorData} className='infosButton'>Update</button>

    </div>
    

  );
};

export default EditHeadshotModalContent;
