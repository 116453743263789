// CreateAccount.js
import React, { useState} from 'react';
import { useDispatch } from 'react-redux';
import { signUp } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import './CreateAccountPageBoxHome.css'; // Make sure to create this CSS file
import { setCurrentLoginPage, setPassEmail, setPassPassword } from '../../store/userSlice'; 

const CreateAccountPageBox = () => {
  const [emailLocal, setEmailLocal] = useState('');
  const [password, setPassword] = useState('');
  const [isJoining, setIsJoining] = useState(false); 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignUp = async () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailLocal) {
      window.alert('Not so fast, You got to enter an email address.');
      return;
    }
    
    if (!emailPattern.test(emailLocal)) {
      window.alert('Not so fast, That is not a valid email address.');
      return;
    }

    if (!password) {
      window.alert('Not so fast, You got to enter a password.');
      return;
    }

    if (password.length < 6) {
      window.alert('Not so fast, Your password got to be at least 6 characters long.');
      return;
    }

    setIsJoining(true); 

    try {
      await signUp({
        username: emailLocal,
        password,
      });
      window.alert('Account created, now you just have to confirm your email and we are good to go');

      dispatch(setPassEmail(emailLocal));
      dispatch(setPassPassword(password));



      dispatch(setCurrentLoginPage('confirmSignUp'));



      // Here you can navigate to the confirm sign up page if you have one
      navigate('/login');
    } catch (error) {
      console.error('Error signing up', error);
      window.alert('Error signing up: ' + error.message);
    } finally {
    setIsJoining(false); // Set joining state back to false regardless of outcome
  }
};

  const handleBackToLogin = () => {
    navigate('/login'); // Navigate back to the login page
  };

  const handleInfoClick = () => {
    navigate('/create-account/whats-up');
  };
  

  return (
    <div className="accountBox">
      <div className="inputContainer">
        <input
            className="input"
            type="email"
            onChange={(e) => setEmailLocal(e.target.value)}
            value={emailLocal}
            placeholder="Email"
        />
        <input
            className="input"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="Password"
        />
      </div>
      <button className="joinButton" onClick={handleSignUp} disabled={isJoining}>
        {isJoining ? 'Joining...' : 'Join'}
      </button>
      <button onClick={handleInfoClick} className="sinfoText">But what am I getting myself into?</button>
      <div className="separator" />
      <button onClick={handleBackToLogin} className="loginText">Back to login</button>

    </div>
  );
};

export default CreateAccountPageBox;
