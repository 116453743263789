import React, { useState, useEffect } from "react";
import { apiInstance } from "../../utils/axiosInstance"; // Import your Axios instance
import './UnsubscribeBox.css';
import { useLocation, useNavigate } from 'react-router-dom';

function UnsubscribeBox() {
  const [token, setToken] = useState('');
  const [emailIdentifier, setEmailIdentifier] = useState('');  // State for email identifier
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);  // Loading state
  const [error, setError] = useState(false);  // Error state

  const location = useLocation();
  const navigate = useNavigate();  // Use navigate to redirect

  // Extract both token and email_identifier from URL parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get('token');
    const emailIdentifierParam = params.get('email_identifier');  // Extract email_identifier
    
    if (tokenParam) {
      setToken(tokenParam); 
    }

    if (emailIdentifierParam) {
      setEmailIdentifier(emailIdentifierParam);  // Set email_identifier in state
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isConfirmed = window.confirm('Are you sure you want to unsubscribe?'); // Confirmation dialog
    if (!isConfirmed) return;

    setLoading(true);
    setError(false);
    setMessage('');

    try {
      // Make the DELETE request using apiInstance, sending both token and email_identifier
      await apiInstance.delete("email/unsubscribe", { 
        data: { 
          token: token, 
          email_identifier: emailIdentifier  // Pass email_identifier along with token
        },
        headers: { "Content-Type": "application/json" } 
      });

      setMessage('Unsubscribe successful. Good riddance. I was too good looking for you anyway. [The machine chuckled to itself]');
      
      // Redirect to homepage after a short delay
      setTimeout(() => {
        navigate('/');  // Redirect using navigate
      }, 6000);
      
    } catch (error) {
      setError(true);
      setMessage('Oh shit oh shit oh shit, error in the unsubscribe process.');
      console.error('Error details:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="unsubscribe-box">
      <p className="unsubscribe-text">What?! You think you're better than me?</p>
      <p className="unsubscribe-text">Please confirm below:</p>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
            <input
            className="unsubscribe-inputs"
            type="hidden"
            name="token"
            value={token}
            />
            <button 
              className="submit-buttons" 
              type="submit" 
              disabled={loading}  // Disable the button during the loading state
              style={{ backgroundColor: loading ? '#d3d3d3' : '#E9DCD1' }}  // Gray out the button while loading
            >
              {loading ? 'Unsubscribing...' : 'Unsubscribe'}
            </button>
        </form>
        {message && <p>{message}</p>}
        {error && <p className="error-message">Please try again later or contact support.</p>}
      </div>
    </div>
  );
}

export default UnsubscribeBox;
