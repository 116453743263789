import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';

function Footer() {
  const location = useLocation();

  return (
    <div className="footer">
      <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link>
      <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About</Link>
      {/* <Link to="/jobs" className={location.pathname === '/jobs' ? 'active' : ''}>Get a job</Link> */}
      <Link to="/home-privacy-policy" className={location.pathname === '/home-privacy-policy' ? 'active' : ''}>Privacy policy</Link>
    </div>
  );
}

export default Footer;