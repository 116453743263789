import React from 'react';
import LoginBox from '../LoginBox/LoginBox';
import {useSelector} from 'react-redux';
import GatewayToHell from '../GatewayToHell/GatewayToHell';
import GatewayToHeaven from '../GatewayToHeaven/GatewayToHeaven';
import GatewayToLogin from '../GatewayToLogin/GatewayToLogin';
import AppStore from '../AppStore/AppStore';
import HomeScreenPointers from '../HomeScreenPointers/HomeScreenPointers';
import './LoginWrapper.css';

function LoginWrapper() {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  return (
    <div className="login-wrapper">

      {!isAuthenticated && <GatewayToLogin />}
      <GatewayToHell />
      <HomeScreenPointers />
      <GatewayToHeaven />
    </div>
  );
}

export default LoginWrapper;