import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReusableBoxContainer from '../ReusableBoxContainer/ReusableBoxContainer';
import { setCurrentStep } from '../../store/applyWriterSlice';
import { setUserRole } from '../../store/userSlice';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import './ApplicationPreview.css'; // Make sure to create and import corresponding CSS
import {apiInstance} from '../../utils/axiosInstance'; // Import the API instance



const ApplicationPreview = () => {
    const dispatch = useDispatch();
    const { currentStep, writersName, published, socialMedia, magPublished, lastWords } = useSelector(state => state.applyWriter);
    const { userEmail, userId } = useSelector(state => state.user);
    const [isApplying, setIsApplying] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  
    const handleReturn = () => {
      dispatch(setCurrentStep(currentStep - 1));
    };
    
  
    // Handle the application save and submission logic here\
    const onApply = async () => {
      setIsApplying(true);
        try {
          // Assuming Auth.currentSession() is handled elsewhere to get JWT token
          const { accessToken, idToken } = await fetchAuthSession();

          const session = await fetchAuthSession();
  
          const jwtToken = session.tokens.idToken.toString();
    
          const applicationData = {
            writersName,
            authorId: '', // Leave this empty if not used
            userId,
            published,
            socialMedia,
            magPublished,
            lastWords,
            email: userEmail,
            reads_public: true
          };
    
          await apiInstance.post('/applications/create', applicationData, {
            headers: { 'Authorization': `Bearer ${jwtToken}` },
          });
    
          dispatch(setUserRole('applicant'));
          // Redirect or show success message
        } catch (error) {
          console.error('Application submission failed:', error);
          alert('There was an issue submitting your application. Please try again.');
        } finally {
          setIsApplying(false); // End applying process
        }
      };

    function truncateText(text, maxWords) {
        const wordsArray = text.split(' ');
        if (wordsArray.length > maxWords) {
          return wordsArray.slice(0, maxWords).join(' ') + ' ...';
        }
        return text;
      }
      
      // Usage inside your component before rendering
      const truncatedLastWords = truncateText(lastWords, 24);
  
    return (
      <div className="applicationPreviewContainer">
        <ReusableBoxContainer title="Email" description={userEmail} />
        <ReusableBoxContainer title="Name you write under" description={writersName} />
        {published !== 'no' && <ReusableBoxContainer title="Published a book or two" />}
        
        {socialMedia.map((item, index) => (
          <ReusableBoxContainer key={index} title={item.platform} description={item.handle} />
        ))}
  
        {magPublished.map((item, index) => (
          <ReusableBoxContainer key={index} title={item.magazine} description={item.link} />
        ))}
  
        {lastWords && <ReusableBoxContainer title="Last words" description={truncatedLastWords} />}

  

        <button className='button-application-apply' onClick={onApply} disabled={isApplying}>
          {isApplying ? 'Applying...' : 'Apply to join the writing class'}
        </button>
        <div className="separator" />
        <button className='button-application-return' onClick={handleReturn}>Return to application</button>

      </div>
    );
  };
  
  export default ApplicationPreview;