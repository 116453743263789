import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenericModal } from '../../store/modalSlice';
import { setUserName } from '../../store/userSlice';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { fetchAuthSession } from 'aws-amplify/auth';

import { apiInstance } from '../../utils/axiosInstance';
import './EditWritingNameModal.css';

const ChangeNameModal = () => {
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.user.userName);
  const userEmail = useSelector((state) => state.user.userEmail);
  const userId = useSelector((state) => state.user.userId);

  const [newName, setNewName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!newName.trim()) {
      setError("Name cannot be empty.");
      return;
    }

    if (newName !== userName) {
      if (window.confirm("Are you sure you want to change your name? This should not be taken lightly.")) {
        await changeName();
        dispatch(hideGenericModal());
      }
    } else {
      dispatch(hideGenericModal());
    }
  };

  const changeName = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const session = await fetchAuthSession();
      const jwtToken = session.tokens.idToken.toString();

      const response = await apiInstance.patch('/platformAuthors/update-name', 
        {
          new_name: newName.trim(),
          old_name: userName,
          email: userEmail,
          userId: userId
        },
        {
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setUserName(newName.trim()));
        dispatch(checkAuthenticationStatus());
        dispatch(hideGenericModal());
      } else {
        throw new Error(response.data.error || 'Failed to change name');
      }
    } catch (error) {
      console.error('Failed to change name:', error);
      setError(error.response?.data?.error || error.message || 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modalBackground">
      <div className="modalesContaineres">
        <h2 className="modalsTitles spaces-writing-names">Change Your Name</h2>
        <div className="tags-dividers"/>
        <div className="forbreaks-hopes spaces-writing-names">
          <label className="sectionTitle spaces-writing-names">Current Name: <span className="userName-normal spaces-writing-names">{userName}</span></label>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="search-input"
            placeholder="Enter new name..."
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <button 
            type="submit" 
            className="kinfosButton-detailss spaces-writing-name" 
            disabled={isLoading || !newName.trim()}
          >
            {isLoading ? 'Changing...' : 'Change Name'}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        <button 
          className="infosButton-details-next" 
          onClick={() => dispatch(hideGenericModal())}
          disabled={isLoading}
        >
          Cancel 
        </button>
      </div>
    </div>
  );
};

export default ChangeNameModal;