import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomeReaders, resetHomeReaders } from '../../store/homeReadersSlice';
import Header from '../../components/Header/Header';
import printingPressProfile from '../../assets/images/printingpressprofile-full.png';
import './DelveIntoReaders.css';
import { differenceInDays, differenceInWeeks, differenceInMonths } from 'date-fns';

const isWithinTimeframe = (readAt, lastRun, timeframe) => {
    const readAtDate = new Date(readAt);
    const lastRunDate = new Date(lastRun);
    
    const daysDiff = differenceInDays(lastRunDate, readAtDate);
    const weeksDiff = differenceInWeeks(lastRunDate, readAtDate);
    const monthsDiff = differenceInMonths(lastRunDate, readAtDate);
  
    if (timeframe === '7days') {
      return daysDiff <= 7;
    } 
    if (timeframe === '6weeks') {
      return weeksDiff <= 6;
    }
    if (timeframe === '12months') {
      return monthsDiff <= 12;
    }
  
    return false; // Default case if none of the timeframes match
  };

const getFullImageUrl = (imagePath) => {
    if (!imagePath) return null;
    if (imagePath.startsWith('http')) return imagePath;
    return `https://shrtrstrz-coverart-dev.s3.amazonaws.com/${imagePath}`;
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
  };


  
  const TopReaderItem = ({ reader, webpSupported }) => (
    <li className="ri-writer-item">
      <Link to={`/writer/${reader.consuming_writer_profile_hash}`} className="ri-writer-link">
        <img 
          src={getFullImageUrl(webpSupported && reader.consuming_writer_image_webp 
            ? reader.consuming_writer_image_webp 
            : reader.consuming_writer_image_jpeg) || printingPressProfile} 
          alt={reader.consuming_writer_name}
          className="ri-writer-image"
        />
        <div className="ri-reader-info">
          <span className="ri-reader-name">{reader.consuming_writer_name}</span>
          <span className="ri-read-count">
            {reader.pieces_read === 1 ? '- read a piece' : `- read ${reader.pieces_read} pieces`}
          </span>
        </div>
      </Link>
    </li>
  );
  
  const RecentReaderItem = ({ reader, webpSupported, selectedTime }) => {
    if (!isWithinTimeframe(reader.read_at, reader.last_run, selectedTime)) {
      return null;
    }
  
    return (
      <li className="ri-writer-item">
        <Link to={`/writer/${reader.consuming_writer_profile_hash}`} className="ri-writer-link">
          <img 
            src={getFullImageUrl(webpSupported && reader.consuming_writer_image_webp 
              ? reader.consuming_writer_image_webp 
              : reader.consuming_writer_image_jpeg) || printingPressProfile} 
            alt={reader.consuming_writer_name}
            className="ri-writer-image"
          />
          <div className="ri-reader-info">
            <span className="ri-reader-name">{reader.consuming_writer_name}</span>
            <span className="ri-read-time">
              {`- last read: ${formatDate(reader.read_at)}`}
            </span>
          </div>
        </Link>
      </li>
    );
    };

  


const ReadersInsightsDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState('7days');
  
  const homeReadersState = useSelector(state => state.homeReaders);
  const { readers, isLoading } = homeReadersState || {};
  
  const userId = useSelector(state => state.user?.userId);
  const userName = useSelector(state => state.user?.userName);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userId && userName) {
      dispatch(fetchHomeReaders({ user_id: userId, writer_name: userName }));
    }

    return () => {
      dispatch(resetHomeReaders());
    };
  }, [dispatch, userId, userName]);

  const handleReturnHome = () => {
    navigate('/writers');
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const supportsWebp = () => {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
  };

  const webpSupported = supportsWebp();

  // Default data structure with zeros
  const defaultData = {
    dashboard_metrics: {
      unique_readers_7d: 0,
      unique_writers_7d: 0,
      off_platform_reads_7d: 0,
      unique_readers_6w: 0,
      unique_writers_6w: 0,
      off_platform_reads_6w: 0,
      unique_readers_12m: 0,
      unique_writers_12m: 0,
      off_platform_reads_12m: 0,
    },
    top_readers_writers: [],
    recent_readers_writers: [],
  };

  // Use the fetched data if available, otherwise use the default data
  const { dashboard_metrics, top_readers_writers, recent_readers_writers } = readers || defaultData;
  console.log('dashboard_metrics', dashboard_metrics);
  console.log('top_readers_writers', top_readers_writers);
  console.log('recent_readers_writers', recent_readers_writers);

  const timeFrameData = {
    '7days': {
      uniqueReaders: dashboard_metrics.unique_readers_7d,
      uniqueWriters: dashboard_metrics.unique_writers_7d,
      offPlatformReads: dashboard_metrics.off_platform_reads_7d
    },
    '6weeks': {
      uniqueReaders: dashboard_metrics.unique_readers_6w,
      uniqueWriters: dashboard_metrics.unique_writers_6w,
      offPlatformReads: dashboard_metrics.off_platform_reads_6w
    },
    '12months': {
      uniqueReaders: dashboard_metrics.unique_readers_12m,
      uniqueWriters: dashboard_metrics.unique_writers_12m,
      offPlatformReads: dashboard_metrics.off_platform_reads_12m
    }
  };

  console.log('timeFrameData', timeFrameData);

  const currentTimeFrameData = timeFrameData[selectedTime];

  console.log('this', top_readers_writers)

  const translateTimeframe = (timeframe) => {
    const mapping = {
      '7days': '7D',
      '6weeks': '6W',
      '12months': '12M',
      '7D': '7days',
      '6W': '6weeks',
      '12M': '12months'
    };
    return mapping[timeframe] || timeframe;
  };
  
  // Usage in filter
  const topReaders = top_readers_writers.filter(reader => 
    translateTimeframe(reader.timeframe) === selectedTime
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="ri-dashboard-container">
      <Header />
      <div className="ri-dashboard-content">
        <h1 className="ri-dashboard-title">Readers</h1>
        
        <div className="ri-dashboard-metrics-container">
          <div className="ri-time-selector">
            {['7days', '6weeks', '12months'].map((time) => (
              <button 
                key={time}
                onClick={() => handleTimeChange(time)} 
                className={`ri-time-button ${selectedTime === time ? 'active' : ''}`}
              >
                {time === '7days' ? '7 Days' : time === '6weeks' ? '6 Weeks' : '12 Months'}
              </button>
            ))}
          </div>
          <div className="subtle-line"></div> 
          
          <div className="ri-metrics-grid">
            <div className="ri-metric-block">
              <h2 className='ri-metric-value'>{currentTimeFrameData.uniqueReaders}</h2>
              <p className='ri-metric-label'>Unique readers</p>
            </div>
            <div className="ri-metric-block">
              <h2 className='ri-metric-value'>{currentTimeFrameData.uniqueWriters}</h2>
              <p className='ri-metric-label'>Unique writers</p>
            </div>
            <div className="ri-metric-block">
              <h2 className='ri-metric-value'>{currentTimeFrameData.offPlatformReads}</h2>
              <p className='ri-metric-label'>Off platform</p>
            </div>
          </div>
        </div>

        <div className="ri-writers-list">
          <h2 className="ri-writers-list-title">Writers who read the most</h2>
          <ul className="ri-readers-list-items">
            {topReaders.map(reader => (
              <TopReaderItem 
                key={reader.consuming_user_id} 
                reader={reader} 
                webpSupported={webpSupported}
              />
            ))}
          </ul>

        </div>
        <div className="ri-writers-list">
          <h2 className="ri-writers-list-title">The most recent ones</h2>
          <ul className="ri-readers-list-items">
            {recent_readers_writers.map(reader => (
              <RecentReaderItem 
                key={reader.consuming_user_id} 
                reader={reader} 
                webpSupported={webpSupported}
                selectedTime={selectedTime}
              />
            ))}
          </ul>
        </div>
        <button onClick={handleReturnHome} className="ri-return-home-button">
          Return to home dashboard
        </button>
      </div>
    </div>
  );
};

export default ReadersInsightsDashboard;