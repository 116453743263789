import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentUser, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';
import { setEmployeeRole } from './employeeSlice';
import {Amplify} from 'aws-amplify';
import awsconfig from '../aws-config'; 

Amplify.configure(awsconfig);

const initialState = {
  userId: null,
  isAuthenticated: false,
  userRole: null,
  userName: null, 
  userEmail: null,
  passPassword: null,
  passEmail: null,
  currentLoginPage: 'login',
  currentForgotPasswordPage: 'forgotPassword',
};

// Async thunk action
export const checkAuthenticationStatus = createAsyncThunk(
  'user/checkAuthenticationStatus',
  async (_, { dispatch }) => {
    try {
      console.log('Step 1: Getting current user');

      const { username } = await getCurrentUser();

      const currentUser = await getCurrentUser();
      console.log('Current user:', currentUser);

      console.log('Step 2: Fetching auth session');
      const { tokens } = await fetchAuthSession();
      console.log('Auth session fetched');

      let userAttributes;
      try {
        console.log('Step 3: Fetching user attributes');
        userAttributes = await fetchUserAttributes();
        console.log('User attributes:', userAttributes);
      } catch (attrError) {
        console.warn('Could not fetch user attributes:', attrError);
        userAttributes = {}; // Use an empty object if attributes can't be fetched
      }
      
      const userRole = userAttributes['custom:userRole'];
      const employeeRole = userAttributes['custom:employeeRole'];
      const usersName = userAttributes.name;
      console.log('UserId:', currentUser.userId);
      const userId = currentUser.userId;

      dispatch(setUserId(userId));
      dispatch(setIsAuthenticated(true));
      dispatch(setEmployeeRole(employeeRole));
      dispatch(setUserRole(userRole));
      dispatch(setUserEmail(userAttributes.email));
      dispatch(setUserName(usersName));

    } catch (error) {

      console.error('Error details:', error.name, error.message, error.stack);
      dispatch(setIsAuthenticated(false));
      dispatch(setUserRole(null));
      dispatch(setUserEmail(null));
      dispatch(setUserName(null));
      dispatch(setUserId(null));
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setPassPassword: (state, action) => {
      state.passPassword = action.payload;
    },
    setPassEmail: (state, action) => {
      state.passEmail = action.payload;
    },
    setCurrentLoginPage: (state, action) => {
      state.currentLoginPage = action.payload;
    },
    setCurrentForgotPasswordPage: (state, action) => {
      state.currentForgotPasswordPage = action.payload;
    },  
  },

  extraReducers: (builder) => {
    // Add reducers that handle the pending, fulfilled, and rejected states of checkAuthenticationStatus here, if needed
  },
});

export const { setCurrentForgotPasswordPage, setPassEmail, setPassPassword, setCurrentLoginPage, setUserName, setUserId, setIsAuthenticated, setUserRole, setUserEmail } = userSlice.actions;
export default userSlice.reducer;
