// Import necessary components and hooks from react-router-dom
import React, { useState, useEffect }  from 'react';
import { Routes, Route, useNavigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import WritersPublishScreen from '../WritersPublishScreen/WritersPublishScreen';
import WritersProfileScreen from '../WritersProfileScreen/WritersProfileScreen';
import Header from '../../components/HeaderPublishPreview/HeaderPublishPreview';
import BooksInPiece from '../../components/BooksInPiecePreview/BooksInPiecePreview';
import { fetchAuthorProfile } from '../../store/authorProfileSlice';
import defaultImage from '../../assets/images/birdie.jpeg';
import './WritersPublishPreview.css';
import printingPressProfile from '../../assets/images/printingpressprofile.png';

import { useMutation, useQueryClient } from 'react-query';

import { fetchAuthSession } from 'aws-amplify/auth';
import { apiInstance } from '../../utils/axiosInstance';
import {resetPublishingProcess} from '../../store/publishingSlice';
import {clearSelections} from '../../store/taggingSlice';


function PublishPreview() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    title,
    piece,
    taggedBooks,
    description,

    genre,
    style,
    isSerial,
    serialFirstProfileHash,
    serialPartNumber,
    previousSerialHash

  } = useSelector(state => state.publishing);

  const queryClient = useQueryClient();


  function resizeImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
        const img = document.createElement('img');
        const reader = new FileReader();
        
        reader.onload = function (e) {
            img.src = e.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(blob => {
                    resolve(new File([blob], file.name, { type: file.type }));
                }, file.type, 1);
            };
        };
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
}



  const [isPublishing, setIsPublishing] = useState(false);

  function stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  function countWords(text) {
    // Use regular expression to replace multiple spaces with a single space and trim spaces
    return text.replace(/\s+/g, ' ').trim().split(' ').length;
  }

  function countWords(text) {
    // Use regular expression to replace multiple spaces with a single space and trim spaces
    return text.replace(/\s+/g, ' ').trim().split(' ').length;
  }

  const wordCount = countWords(stripHtml(piece));

  const { mutate: publishPiece, isLoading, isError } = useMutation(async (formData) => {
    const session = await fetchAuthSession();
    const jwtToken = session.tokens.idToken.toString();

    console.log('formData', formData);

    const response = await apiInstance.post('/pieces/create', formData, {
        headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
}, {
    onSuccess: (data) => {
        console.log('Piece published successfully:', data);
        queryClient.invalidateQueries(['pieces']);
        dispatch(clearSelections()); // Assuming you have imported the slice as taggingSlice
        dispatch(resetPublishingProcess());
        navigate('/writers');
        setIsPublishing(false);
    },
    onError: (error) => {
        console.error('Error publishing piece:', error);
        alert('Failed to publish the piece. Please try again.');
        alert(error);
        setIsPublishing(false);
        
    },
});
  

  const getLengthCategory = (text) => {
    const wordCount = countWords(stripHtml(text));
    if (wordCount <= 250) return 'Bite Sized';
    if (wordCount <= 1500) return 'Flash';
    if (wordCount <= 5000) return 'Shorter Story';
    return 'Short Story';
  };


  const { userId, userName } = useSelector(state => state.user);


  const currentPhoto = useSelector(state => state.publishing.currentPhoto) || defaultImage;
  const { data: authorProfile, isLoading: isLoadingProfile, error: errorProfile } = useSelector((state) => state.authorProfile);


  // Fetch author profile data
  useEffect(() => {
      if (!authorProfile && !isLoadingProfile) {
        // Trigger the fetch only if authorProfile hasn't been loaded and not currently loading
        dispatch(fetchAuthorProfile({ userId, userName }));
      }
      // It's essential to include isLoading in the dependency array if its value is used in the condition
    }, [dispatch, userId, userName, authorProfile, isLoadingProfile]);
  // Use useNavigate for navigation actions
  

  
  if (isLoadingProfile || !authorProfile) {
    return <div>Loading author information...</div>;
  }

  const handleImageLoad = (e) => {


    const img = e.target;
    if (img.naturalWidth * 1.0< img.naturalHeight) {

        // Adjust the image height to match its width for landscape images
        img.style.width = '100%'; // Set the width to 40% of its container
        img.style.height = `${img.offsetWidth * 1}px`;  // Match the height to the current width
        img.style.objectFit = 'cover'; // Ensure the image covers the area, cropping as necessary
    } else {

        // Apply or reset styles for portrait/square images as necessary
        img.style.width = '100%'; // Maintain the 40% width
        img.style.height = 'auto'; // Allow the height to adjust naturally
    }
};

  const navigateToProfile = () => {
    navigate('/writers/publish/details'); // Assuming '/profile' is the route for the profile page
  };


const saveChanges = async () => {
  try {
  setIsPublishing(true);
  const formData = new FormData();
  formData.append('title', title);
  formData.append('piece_content', piece);
  formData.append('piece_genre', genre);
  formData.append('piece_style', style);
  formData.append('taggedBooks', JSON.stringify(taggedBooks));
  formData.append('userId', userId);
  formData.append('profile_hash', authorProfile.profile_hash);
  formData.append('author_name', authorProfile.name);
  formData.append('publishing_user', userId);
  formData.append('description', description);
  formData.append('piece_length', wordCount);
  const showInFeed = authorProfile.profile_hash === 'AcS1Na49' ? 'false' : 'true';
  formData.append('show_in_the_feed', showInFeed);
  if (isSerial) {
    formData.append('is_serial', 'true');
    formData.append('serial_first_profile_hash', serialFirstProfileHash);
    formData.append('serial_part_number', serialPartNumber.toString());
    formData.append('previous_serial_hash', previousSerialHash);

  }

  console.log('FormData initialized:', formData);

  if (currentPhoto) {
            try {
                const response = await fetch(currentPhoto);
                const blob = await response.blob();

                console.log('Blob created:', blob);

                const mimeType = blob.type;
                const fileExtension = mimeType.split('/')[1];
                const filename = `uploaded_image.${fileExtension}`;
                const originalFile = new File([blob], filename, { type: mimeType });

                console.log('Original file size:', originalFile.size);

                // Resize the image
                const resizedFile = await resizeImage(originalFile, 2000, 2000);
                console.log('Resized file size:', resizedFile.size);

                // Check the size of the resized file
                if (resizedFile.size > 10 * 1024 * 1024) { // 10MB limit
                    alert('Resized file size still exceeds the maximum limit of 10MB. Please upload a smaller file.');
                    setIsPublishing(false);
                    return;
                }

                formData.append('image', resizedFile);
                console.log('File appended to FormData:', resizedFile);
            } catch (error) {
                console.error('Error processing image:', error);
                alert('Error processing image. Please try again.');
                setIsPublishing(false);
                return; // Exit the function to prevent further execution
            }
        }

        // Proceed to publish the piece
        await publishPiece(formData);
    } catch (error) {
        console.error('Error in saveChanges:', error);
        alert(`Error processing image: ${error.message || error}. Please try again or contact Fred if problem persists.`);
        setIsPublishing(false);
    }
};

  return (
    <div className="preview-piece-page">
      <Header />
      <div className="preview-contents-container">
        <div className="previews-author-info">
          <div className='preview-author-only'>
            <img src={authorProfile.profile_image_jpeg||printingPressProfile} alt="Author" className="preview-author-photo" />
            <span className="preview-author-name">{authorProfile.name}</span>
          </div>
          <span className="preview-length-indicator">{getLengthCategory(piece)}</span>
        </div>

        {currentPhoto && (
          <div className="preview-piece-image-container">
            <img src={currentPhoto} alt="Cover Art" className="preview-piece-image" onLoad={handleImageLoad}/>
          </div>
        )}
        <div className="preview-genre-style-container">
          <span>
            Genre: <span className="preview-italic-text">{genre !== 'blank' ? genre : 'Not specified'}</span>
          </span>
          <span>
            Style: <span className="preview-italic-text">{style !== 'blank' ? style : 'Not specified'}</span>
          </span>
        </div>
        <div className="preview-divider"></div>
        {taggedBooks && taggedBooks.length > 0 && (
          <>
            <div className="preview-promoted-books-text">Promoted books</div>
            <BooksInPiece feedItems={taggedBooks} />
            <div className="preview-divider"></div>
          </>
        )}
        <h3 className="preview-pieces-title">
          {title} <span className="preview-authors-name">by {authorProfile.name}</span>
        </h3>
        <div className="preview-piece-content" dangerouslySetInnerHTML={{ __html: piece }}></div>


        <div className="preview-divider"></div>
        <button className="buttonPreviewPiece" style={{ fontWeight: 'bold' }} onClick={!isPublishing ? saveChanges : undefined} disabled={isPublishing}>
          {isPublishing ? 'Publishing...' : 'Publish piece'}
        </button>

        <button className="buttonPreviewPiece" onClick={navigateToProfile} >
          Return to edit
        </button>



      </div>
    </div>

  );
}

export default PublishPreview;
