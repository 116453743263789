import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedTime } from '../../store/homeSlice';
import './HomeSettingsModal.css';
import { signOut } from 'aws-amplify/auth';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { resetAuthorProfile } from '../../store/authorProfileSlice';
import { clearSelections } from '../../store/taggingSlice';
import { resetPublishingProcess } from '../../store/publishingSlice';
import { showGenericModal, hideGenericModal } from '../../store/modalSlice';
import { resetHomeStats } from '../../store/homeSlice';
import { apiInstance } from "../../utils/axiosInstance";
import { fetchAuthSession } from 'aws-amplify/auth';

const HomeSettingsModal = () => {
  const dispatch = useDispatch();
  const selectedTime = useSelector(state => state.home.selectedTime);
  const navigate = useNavigate();
  const stats = useSelector(state => state.home.stats);
  const userId = useSelector(state => state.user.userId);
  const userName = useSelector(state => state.user.userName);

  const timeOptions = [
    { label: "the last seven days", value: "7days" },
    { label: "the last six weeks", value: "6weeks" },
    { label: "the last 12 months", value: "12months" },
  ];

  const handleTimeSelection = (value) => {
    dispatch(setSelectedTime(value));
    dispatch(hideGenericModal()); 
  };

  const nameModal = () => {
    dispatch(hideGenericModal()); // Hide the current modal
    dispatch(showGenericModal({
      modalType: 'EDIT_WRITING_NAME',
    })); // Immediately show the next modal
  };

  const handleLogout = async () => {
    try {
      console.log('Stats before reset:', stats);
      await signOut();
      dispatch(checkAuthenticationStatus());
      navigate("/"); // Redirect to home page after logout
      dispatch(resetAuthorProfile());
      dispatch(clearSelections());
      dispatch(resetPublishingProcess());
      dispatch(hideGenericModal());  // Dispatch the function to hide the modal
      dispatch(resetHomeStats());

      console.log('Stats after reset:', stats);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleInfo = () => {
    console.log("Display Info");
    dispatch(hideGenericModal());
    navigate("/writers/finer-details");
  };

  const handleToggleReadsPublic = async () => {
    const confirmMessage = stats.reads_public 
      ? "Are you sure you don't want other writers to see you read their work?"
      : "Enable other writers to see that you read their work?";
  
    if (window.confirm(confirmMessage)) {
      try {
        const session = await fetchAuthSession();
        const jwtToken = session.tokens.idToken.toString();

        const endpoint = stats.reads_public ? '/platformAuthors/reads-private' : '/platformAuthors/reads-public';

        const response = await apiInstance.put(endpoint, 
          {
            user_id: userId,
            author_name: userName
          },
          {
            headers: { Authorization: `Bearer ${jwtToken}` }
          }
        );

        if (response.status === 200) {
          // Update the local state with the new reads_public value
          dispatch({
            type: 'home/updateStats',
            payload: {
              ...stats,
              reads_public: !stats.reads_public
            }
          });
          console.log('Reads public setting updated successfully');
          dispatch(hideGenericModal());
        } else {
          console.error('Failed to update reads public setting');
        }
      } catch (error) {
        console.error('Error updating reads public setting:', error);
        // Handle the error state in your UI
      }
    }
  };

  return (
    <div className="homeSettingsModalContainer">
      <div className="homeSettingsModalOptions">
        <h3 className="homeSettingsModalTitle">See stats for:</h3>
        {timeOptions.map(option => (
          <button
            key={option.value}
            onClick={() => handleTimeSelection(option.value)}
            className={`homeSettingsModalButton ${selectedTime === option.value ? "selected" : ""}`}
          >
            {selectedTime === option.value ? `- ${option.label} •` : `- ${option.label}`}
          </button>
        ))}
        {stats && stats.reads_public !== undefined && (
          <h3 onClick={handleToggleReadsPublic} className="homeSettingsModalActionLink">
            {stats.reads_public ? "A link to stop promoting yourself to other writers" : "Enable other writers to see that you read their work"}
          </h3>
        )}
        <h3 onClick={handleInfo} className="homeSettingsModalActionLink">
          A link to all the fine details you might be interested in knowing 
        </h3>

        {/* <h3 onClick={nameModal} className="homeSettingsModalActionLink">
          Edit the name you write under
        </h3> */}
        <h3 onClick={handleLogout} className="homeSettingsModalActionTitle">
          Log out
        </h3>
      </div>
    </div>
  );
};

export default HomeSettingsModal;