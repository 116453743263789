import { configureStore } from '@reduxjs/toolkit';
import employeeReducer from './employeeSlice';
import modalReducer from './modalSlice';
import userReducer from './userSlice';
import applicationsReducer from './applicationsSlice';
import applyWriterReducer from './applyWriterSlice'; // Import the applyWriter reducer
import editWriterSlice from './editWriterSlice'; // Import the editWriter reducer
import publishingReducer from './publishingSlice';
import taggingReducer from './taggingSlice'; 
import authorProfileReducer from './authorProfileSlice'; // Import the authorProfile reducer
import homeReducer from './homeSlice';
import theFeedReducer from './theFeedSlice';
import homeReadersReducer from './homeReadersSlice';



export const store = configureStore({
  reducer: {
    user: userReducer,
    employee: employeeReducer,
    modal: modalReducer,
    applications: applicationsReducer,
    applyWriter: applyWriterReducer, // Add the applyWriter reducer to the store
    editWriter: editWriterSlice,
    publishing: publishingReducer,
    tagging: taggingReducer,
    authorProfile: authorProfileReducer, // Add the authorProfile reducer to the store
    home: homeReducer,
    theFeed: theFeedReducer,
    homeReaders: homeReadersReducer, 



  },
});