import React, { useState } from 'react';
import { useDispatch, useSelector} from 'react-redux'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { setCurrentLoginPage, setPassEmail, setPassPassword, checkAuthenticationStatus } from '../../store/userSlice'; 
import './LoginBox.css'; // Import the CSS file for styling
import { getCurrentUser, signIn } from 'aws-amplify/auth';
import { signInWithRedirect } from 'aws-amplify/auth';
import { resetApplication } from '../../store/applyWriterSlice';
import google from '../../assets/images/android_light_rd_SI.svg';


const LoginBox = ( ) => {
  const [emailLocal, setEmailLocal] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const location = useLocation();
  const { stats, isLoading, error } = useSelector((state) => state.home);

  const handleLogin = async () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    console.log('here', stats);
    console.log('error',error);

    if (!emailLocal) {
      alert('Not so fast, You got to enter an email address.');
      return;
    }

    if (!emailPattern.test(emailLocal)) {
      alert('Not so fast, That is not a valid email address.');
      return;
    }

    if (!password) {
      alert('Not so fast, You got to enter a password.');
      return;
    }

    if (password.length < 6) {
      alert('Not so fast, Your password got to be at least 6 characters long.');
      return;
    }

    try {

      dispatch(resetApplication());
      
      await signIn({ username: emailLocal, password: password });
      console.log('heree', stats);
      const { username } = await getCurrentUser();
      console.log('hereex2', stats);
      console.log('herperizer', stats);
      dispatch(checkAuthenticationStatus());
      console.log('Whatsemecallit', stats);
      if (!location.pathname.startsWith('/writers')) {
        navigate('/');
      }
    } catch (error) {
      
      if (error.name === 'UserUnAuthenticatedException') {
        dispatch(setPassEmail(emailLocal));
        dispatch(setPassPassword(password));
        dispatch(setCurrentLoginPage('confirmSignUp'));
      } else {
        console.log('Error code', error.name);
        console.error('Error signing in', error);
        alert('Error signing in: ' + error.message);
      }
    }
  };

  const handleForgotPassword = () => {
    const isWritersPage = location.pathname.startsWith('/writers');
    const forgotPasswordPath = isWritersPage ? '/writers/forgot-password' : '/forgot-password';
    navigate(forgotPasswordPath);
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithRedirect({ provider: 'Google' });
      // Note: The rest of this function may not execute immediately
      // as the page will redirect to Google's sign-in page
    } catch (error) {
      console.error('Error initiating Google sign-in', error);
      alert('Error initiating Google sign-in: ' + error.message);
    }
  };

  return (
    <div className="loginBox">
      <div className="inputContainer">
        <input
          className="input"
          type="email"
          onChange={(e) => setEmailLocal(e.target.value)}
          value={emailLocal}
          placeholder="Email"
        />
        <input
          className="input"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="Password"
        />
      </div>
      <button className="submitButton" onClick={handleLogin}>
        Log in
      </button>
      <div className="separator" />
      {/* <img className="googleSignIn" src={google} alt="Google logo" onClick={handleGoogleSignIn}/> */}
      <button className="forgotPassword" onClick={handleForgotPassword}>
        Forgot password?
      </button>
    </div>
  );
};

export default LoginBox;
