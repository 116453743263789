import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import './Find.css';
import { apiInstance } from '../../utils/axiosInstance';
import AuthorSummarySearch from '../../components/AuthorSummarySearch/AuthorSummarySearch';

function Find() {
  const [query, setQuery] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    if (!query.trim()) {
      setResponseMessage('Please enter a search term.');
      return;
    }

    setIsLoading(true);
    setResponseMessage('');

    try {
      const response = await apiInstance.get('/tag-search/writers', {
        params: { query },
      });

      setResults(response.data); // Assuming response.data is an array of author results
      setResponseMessage(`Success! Found ${response.data.length} results.`);
    } catch (error) {
      console.error('Search error:', error);
      setResponseMessage('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="find">
      <Header />
      <div className="find__search-container">
        <input
          type="text"
          className="find__search-bar"
          placeholder="Search for a writer..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress} // Add this line to listen for the Enter key
        />
        <button className="find__submit-button" onClick={handleSearch} disabled={isLoading}>
          {isLoading ? 'Searching...' : 'Search'}
        </button>
      </div>
      <div className="find__response">
        <div className="find__results-list">
          {results.map((author, index) => (
            <AuthorSummarySearch key={index} author={author} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Find;
