import React, { useState } from 'react';
import './EditBioPicModal.css';
import ReusableBoxContainer from '../ReusableBoxContainer/ReusableBoxContainer';

import defaultVerticalImage from '../../assets/images/defaultVerticalmage.png';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenericModal } from '../../store/modalSlice';
import { setEditableAuthorsData, setVerticalImageFile } from '../../store/editWriterSlice';
import imageCompression from 'browser-image-compression';


const EditBioPicModal = () => {
  const dispatch = useDispatch();
  const authorData = useSelector((state) => state.editWriter.editableAuthorsData);
  const [isImageTouched, setIsImageTouched] = useState(false);

  const { vertical_image_jpeg, vertical_image_webp, ...restOfAuthorData } = authorData;

  const [selectedImage, setSelectedImage] = useState(authorData.vertical_image || authorData.vertical_image_webp || defaultVerticalImage);


  const originalVerticalImageFile = useSelector((state) => state.editWriter.verticalImageFile);

  

  const fileInputRef = React.createRef();

  const [previousImage, setPreviousImage] = useState(authorData.vertical_image_webp || defaultVerticalImage);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Compress and correct orientation
        const options = {
          maxSizeMB: 1,
          useWebWorker: true,
          alwaysKeepResolution: true,
        };
        const compressedFile = await imageCompression(file, options);
        const base64 = await imageCompression.getDataUrlFromFile(compressedFile);
  
        // Set the corrected base64 image
        setSelectedImage(base64);
        dispatch(setVerticalImageFile(base64));
        setIsImageTouched(true);
      } catch (error) {
        console.error("Error compressing and correcting image:", error);
      }
    }
  };
  

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const revertToPreviousImage = () => {
    setSelectedImage(previousImage);
    dispatch(setVerticalImageFile(originalVerticalImageFile));
    setIsImageTouched(false); 
  };


  const revertToDefaultImage = () => {
    // Logic to revert to the default image
    setSelectedImage(defaultVerticalImage);
    dispatch(setVerticalImageFile(null));
    setIsImageTouched(true); 
  };

  const handleUpdateAuthorData = () => {
    if (isImageTouched) {
      const updatedAuthorData = {
        ...restOfAuthorData,
        vertical_image: selectedImage,
      };
      dispatch(setEditableAuthorsData(updatedAuthorData));
    }
    dispatch(hideGenericModal());
    // Here you could also close the modal or show a success message
  };

  return (
    <div className="modal">
      <div className="contentBox">

        <div className="titleContainer">
          <p className="modalTitle">Upload portrait:</p>
        </div>
        {selectedImage && <img src={selectedImage} alt="Selected" className="image-preview" />}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
          className="fileInput"
          accept="image/*"
          style={{ display: 'none' }} // Hide the actual file input
        />

        <button onClick={handleUploadClick} className='infosButton'>Upload image</button>
        <div className="textButtonContainer">
          <button onClick={revertToPreviousImage} className="textButton">Revert to previous image</button>
          <button onClick={revertToDefaultImage} className="textButton">Revert to default image</button>
        </div>
      </div>
      <ReusableBoxContainer
        title="You gotta know:"
        description="This pic has got to be at least as long as it is wide. In the biz they call this a portrait. You can upload any pic but if it's wider than it is long it'll cut some of it off."
        // Add any other props as needed
      />
      <button onClick={handleUpdateAuthorData} className='infosButton'>Update</button>

    </div>
    

  );
};

export default EditBioPicModal;
