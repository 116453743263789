import Header from '../../components/Header/Header';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signIn } from 'aws-amplify/auth';
import './EmployeeLoginForm.css';
import { checkEmployeeStatus } from '../../store/employeeSlice';

function EmployeeLoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();

  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(null);

    // Client-side validation
    if (!email) {
        setErrorMessage('Not so fast, you got to enter an email address.');
        setIsSubmitting(false);
        return;
    }
    
    if (!emailPattern.test(email)) {
        setErrorMessage('Not so fast, that is not a valid email address.');
        setIsSubmitting(false);
        return;
    }
    
    if (!password) {
        setErrorMessage('Not so fast, you got to enter a password.');
        setIsSubmitting(false);
        return;
    }
    
    if (password.length < 6) {
        setErrorMessage('Not so fast, your password got to be at least 6 characters long.');
        setIsSubmitting(false);
        return;
    }
  

    try {
      await signIn({ username: email, password: password }); 
      dispatch(checkEmployeeStatus());  // This will check the employee status and update the Redux state accordingly
      window.location.reload();
    } catch (error) {
      console.error("Authentication error:", error);
      setErrorMessage('Login error: ' + error.message);
      
    }
    setIsSubmitting(false);
  };

  return (
    <div className="login-box">
      <Header />
      <input
        className="email-inputs"
        type="text"
        name="email"
        placeholder="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <input
        className="email-inputs"
        type="password"
        name="password"
        placeholder="Password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <input className="submit-button-login" type="submit" value="Login" disabled={isSubmitting} onClick={handleSubmit} />
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </div>
  );
}

export default EmployeeLoginForm;
