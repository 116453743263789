// AppFormIntro.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { setCurrentStep } from '../../store/applyWriterSlice';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { signOut } from 'aws-amplify/auth';
import './AppFormIntro.css'; // Make sure to create this CSS file

const AppFormIntro = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentStep = useSelector(state => state.applyWriter.currentStep);

    const handleNextStep = () => {
        dispatch(setCurrentStep(currentStep + 1));
    };

    const handleSignOut = async () => {
        try {
            await signOut();
            dispatch(checkAuthenticationStatus());
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    const handleInfoClick = () => {
        navigate('/writers/application-whats-up');
      };
      

    return (
        <div className="introContainer">
            <div className="contentView">
                <h2 className="bold">Apply to join the writing class</h2>
                <p className="text">
                    Tell a story, write a poem, test some dialogue, critique the world, share some words.
                    Apply to be a writer on the platform below.
                </p>
                <button className="applyButton" onClick={handleNextStep}>Apply</button>
                <button onClick={handleInfoClick} className="spinfoText">But what am I getting myself into?</button>
                <div className="separatored"></div>
                <button className="signOutButton" onClick={handleSignOut}>Sign Out of Account</button>
            </div>
        </div>
    );
};

export default AppFormIntro;
