import React, { useState, useEffect } from 'react';
import './AuthorSummarySearch.css';
import defaultVerticalImage from '../../assets/images/defaultVerticalmage.png';
import { useNavigate } from 'react-router-dom';

const getFullImageUrl = (imagePath) => {
    if (!imagePath) return null;
    if (imagePath.startsWith('http')) return imagePath;
    return `https://shrtrstrz-coverart-dev.s3.amazonaws.com/${imagePath}`;
};

const AuthorSummarySearch = ({ author }) => {
    const [supportsWebp, setSupportsWebp] = useState(false);
    const navigate = useNavigate();

    console.log('author', author);

    const determineGeneration = (yearOfBirth) => {
        if (!yearOfBirth) return null;
        const lastDigit = yearOfBirth % 10;
        const decade = Math.floor(yearOfBirth / 10) * 10;

        if (lastDigit === 9) return `${decade + 10}s and ${decade + 20}s`;
        else if (lastDigit === 0 || lastDigit === 1) return `${decade}s and ${decade + 10}s`;
        return `${decade + 10}s`;
    };

    const handleProfileClick = () => {
        navigate(`/writer/${author.profile_hash}`);
    };

    const generation = determineGeneration(author.year_of_birth);

    // Use `getFullImageUrl` to handle image paths
    const verticalImage = getFullImageUrl(author.images?.vertical) || defaultVerticalImage;
    const currentLocation = `${author.current_location?.region?.label || ''}${author.current_location?.country?.label ? `, ${author.current_location?.country?.label}` : ''}`.trim();
    const homeLocation = `${author.home_location?.region?.label || ''}${author.home_location?.country?.label ? `, ${author.home_location?.country?.label}` : ''}`.trim();

    useEffect(() => {
        const canvas = document.createElement('canvas');
        setSupportsWebp(!!(canvas.getContext && canvas.getContext('2d')) && canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0);
    }, []);

    return (
        <div className="authorSummarySearch-wrapper">
            <div className="authorSummarySearch-topDivider" />
            <div className="authorSummarySearch-topCard">
                <div className="authorSummarySearch-imageContainer">
                    <picture>
                        <img src={verticalImage} alt="Author" className="authorSummarySearch-image" />
                    </picture>
                </div>
                <div className="authorSummarySearch-info">
                    <div className="authorSummarySearch-nameContainer">
                        <h3 className="authorSummarySearch-name">{author.name}</h3>
                    </div>
                    {generation && (
                        <p className="authorSummarySearch-infoDetail">
                            <span className="authorSummarySearch-label">Grew up in the:</span> {generation}
                        </p>
                    )}
                    {author.major_genre && (
                        <p className="authorSummarySearch-infoDetail">
                            <span className="authorSummarySearch-label">Major Genre:</span> {author.major_genre}
                        </p>
                    )}
                    {currentLocation && (
                        <p className="authorSummarySearch-infoDetail">
                            <span className="authorSummarySearch-label">Lives in:</span> {currentLocation}
                        </p>
                    )}
                    {homeLocation && (
                        <p className="authorSummarySearch-infoDetail">
                            <span className="authorSummarySearch-label">From:</span> {homeLocation}
                        </p>
                    )}
                </div>
            </div>
            <div className="authorSummarySearch-buttonRow">
                <button onClick={handleProfileClick} className="authorSummarySearch-button">
                    <span className="authorSummarySearch-buttonText">Go to Profile</span>
                </button>
            </div>
        </div>
    );
};

export default AuthorSummarySearch;
