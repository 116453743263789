import React, { useEffect, useRef, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WriterTopCard from '../../components/WriterTopCard/WriterTopCard';
import Header from '../../components/HeaderEditProfile/HeaderEditProfile';
import ProfileDetailsEdit from '../../components/ProfileDetailsEdit/ProfileDetailsEdit';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { showGenericModal } from '../../store/modalSlice';
import { isEqual } from 'lodash';
import { fetchAuthSession } from 'aws-amplify/auth';
import { setEditableAuthorsData, setPreEditableAuthorsData } from '../../store/editWriterSlice';

import { useQueryClient } from 'react-query';
import { resetAuthorProfile } from '../../store/authorProfileSlice';

  

// Correct the import paths according to your web project structure
import printingPressProfile from '../../assets/images/printingpressprofile.png';
import './EditProfileScreen.css'; // Your CSS file for the web styles
import { apiInstance } from '../../utils/axiosInstance';


const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const bioImageRef = useRef(null); // Create a ref for the image
  const { pathname } = useLocation();
  const userId = useSelector((state) => state.user.userId);
  const userName = useSelector((state) => state.user.userName);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const editedAuthorData = useSelector(state => state.editWriter.editableAuthorsData);
  const preEditedAuthorData = useSelector(state => state.editWriter.preEditableAuthorsData);
  const profileImageFile = useSelector(state => state.editWriter.profileImageFile);
  const verticalImageFile = useSelector(state => state.editWriter.verticalImageFile);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  
  // Create a deep copy of authorsData for editing
  const editableAuthorsData = useSelector((state) => state.editWriter.editableAuthorsData);


  useEffect(() => {
    if (editableAuthorsData === null) { // Check if editableAuthorsData is null and then fetch data

      setLoading(true);

      const fetchAuthorProfile = async () => {
        try {
          const url = `/platformAuthors/summary/fetch?user_id=${userId}&author_name=${encodeURIComponent(userName)}`;
          const response = await apiInstance.get(url);
          // Assume a Redux action named setEditableAuthorsData that updates the author's data
          dispatch(setEditableAuthorsData(response.data));
          dispatch(setPreEditableAuthorsData(response.data));
          setLoading(false);
        } catch (error) {
          console.error('Error fetching the author profile:', error);
          setLoading(false);

        }
      };

      fetchAuthorProfile();
    } else {
      // Data does not exist, proceed to fetch it and setLoading true is handled within fetchAuthorProfile
      setLoading(false);
    }
    
  }, [dispatch, navigate, editableAuthorsData, userId, userName]);


  const bioImageSrcs = editableAuthorsData?.profile_image || printingPressProfile;

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

const mutation = useMutation(async (formData) => {
  const session = await fetchAuthSession();
  const jwtToken = session.tokens.idToken.toString();

  console.log('FormData contents:');
  for (let [key, value] of formData.entries()) {
    if (value instanceof File) {
        console.log(key, value.name, value.type, value.size);
    } else {
        console.log(key, value);
    }
}

  const response = await apiInstance.patch('/platformAuthors/update', formData, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`,
      },
    });

    return response.data;
  }, {

      onSuccess: (data) => {
          dispatch(resetAuthorProfile());
          // Assuming the response data structure is suitable for directly updating the Redux store
          queryClient.invalidateQueries(['authorProfile', userId, userName]);
          navigate('/writers/profile');
          window.scrollTo(0, 0);
          setIsSaving(false);
          // Navigate back to the profile page or wherever appropriate
        },
        onError: (error) => {
          console.error('Error updating profile:', error);
          let errorMessage = 'Failed to save changes to profile.';

          // Display detailed error message if available
          if (error.response && error.response.data) {
            const serverMessage = error.response.data.message || JSON.stringify(error.response.data);
            errorMessage += ` Server response: ${serverMessage}`;
          } else {
            errorMessage += ' Please check your connection or try again later.';
          }

          alert(errorMessage);
          setIsSaving(false);
          // Handle the error appropriately
        },
      });


useEffect(() => {
  const adjustImage = () => {
    const img = bioImageRef.current;
    if (img && img.complete) {
      if (img.naturalWidth > img.naturalHeight) {
        img.style.height = `${img.offsetWidth}px`;
        img.style.objectFit = 'cover';
      } else {
        img.style.height = 'auto';
        img.style.objectFit = 'contain';
      }
    }
  };

  adjustImage();
}, [bioImageSrcs]); // Depend on bioImageSrc to re-run this effect if the image source changes

const navigateToProfile = () => {
  navigate('/writers/profile');
  window.scrollTo(0, 0);
};

const saveChanges = () => {
  setIsSaving(true);
  let formData = new FormData();

  formData.append('user', userId);
  formData.append('name', userName);

  Object.keys(editedAuthorData).forEach(key => {
      if (!isEqual(editedAuthorData[key], preEditedAuthorData[key])) {
          if (key === 'profile_image') {
              if (profileImageFile) {
                  if (typeof profileImageFile === 'string' && profileImageFile.startsWith('data:')) {
                      const file = dataURLtoFile(profileImageFile, 'profile_image.png');
                      formData.append('profile_image', file);
                  } else {
                      formData.append('profile_image', profileImageFile);
                  }
              } else {
                  formData.append('profile_image_reset', 'true');
              }
          } else if (key === 'vertical_image') {
              if (verticalImageFile) {
                  if (typeof verticalImageFile === 'string' && verticalImageFile.startsWith('data:')) {
                      const file = dataURLtoFile(verticalImageFile, 'vertical_image.png');
                      formData.append('vertical_image', file);
                  } else {
                      formData.append('vertical_image', verticalImageFile);
                  }
              } else {
                  formData.append('vertical_image_reset', 'true');
              }
          } else if (['in_bio_visible', 'home_country_name', 'home_region_name', 'region_name', 'country_name', 'magazine_links', 'social_links'].includes(key)) {
              formData.append(key, JSON.stringify(editedAuthorData[key]));
          } else {
              formData.append(key, editedAuthorData[key]);
          }
      }
  });

  console.log('FormData contents before mutation:');
  for (let [key, value] of formData.entries()) {
      console.log(key, value);
  }

  mutation.mutate(formData);
  
};

useEffect(() => {
  // Compare the editedAuthorData and preEditedAuthorData for changes
  const dataHasChanged = !isEqual(editedAuthorData, preEditedAuthorData);
  setHasChanges(dataHasChanged);
}, [editedAuthorData, preEditedAuthorData]); 


if (loading) {
  return <div>Loading...</div>; // or any other loading indicator
}


  

  // Convert the touchable opacity to a button for the we

  const handlePress = (modalType) => {
    dispatch(showGenericModal({
      modalType: modalType, // This could be 'EDIT_BIO' or any other modal type you have.
      modalProps: { // Assuming you want to pass some props based on the modal type
      }
    }));
  };

  const bioImageSrc = editableAuthorsData.profile_image_jpeg || printingPressProfile;





  return (
    <div className="editProfileContainer">
      <Header />
      <WriterTopCard summary={editableAuthorsData} />
      <div className="infoBox">
        <button className="infoButton" onClick={() => handlePress('EDIT_BIO')}>Swap out portrait in bio</button>
        {editableAuthorsData.short_bio && (
          <div className="bioContainer">
            <p className="authorBio">{editableAuthorsData.short_bio}</p>
          </div>
        )}
        <div className="editProfilePage-divider"/>
        <button className="infosButtoneasy" onClick={() => handlePress('EDIT_LINKS_AND_SOCIALS')}>Edit links and social media</button>
        
        <div className="centerWrapper">
          <div className="headshotLink" onClick={() => handlePress('EDIT_HEADSHOT')}>
            <picture>
              {editableAuthorsData.profile_image_webp && (
                <source srcSet={editableAuthorsData.profile_image_webp} type="image/webp" />
              )}
              {editableAuthorsData.profile_image_jpeg && (
                <source srcSet={editableAuthorsData.profile_image_jpeg} type="image/jpeg" />
              )}
              <img
                src={editableAuthorsData.profile_image_webp || editableAuthorsData.profile_image_jpeg || editableAuthorsData.profile_image || printingPressProfile}
                alt="Headshot"
                className="profileImage"
              />
            </picture>
            <span className="linkText">Swap out headshot</span>
          </div>
        </div>
        
        
        <ProfileDetailsEdit originalData={editableAuthorsData} handlePress={handlePress} />
        <div className="bottomAction">
          <button
            className={`infoButton `}
            onClick={hasChanges ? saveChanges : undefined}
            disabled={!hasChanges || isSaving}
            style={{
              fontWeight: hasChanges ? 'bold' : 'normal', // Bold when changes are present
              opacity: (!hasChanges || isSaving) ? 0.6 : 1, // Dimmed when disabled
              cursor: (!hasChanges || isSaving) ? 'not-allowed' : 'pointer', // Not clickable when disabled
              width: '100%', // Full width button
              marginBottom: '0px', // Spacing between buttons
              marginTop: '0px'
            }}
          >
            {isSaving ? 'Saving...' : 'Save Changes'}
          </button>
          <button className="infoButton" onClick={navigateToProfile}     style={{
            display: 'block', // Ensures the button is treated as a block element
            width: '100%',
            marginTop: '10px',
            cursor: 'pointer',
          }}> 
            Discard
          </button>
        </div>
      </div>
      
    </div>
  );
};


export default EditProfile;