// Import necessary components and hooks from react-router-dom
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import TwoAxisGraph from '../../components/TwoAxisGraph/TwoAxisGraph';
import ThreeLinedGraph from '../../components/ThreeLinedGraph/ThreeLinedGraph';
import DashHorizontalFeedPieces from '../../components/DashHorizontalFeedPieces/DashHorizontalFeedPieces';
import DashHorizontalFeedBooks from '../../components/DashHorizontalFeedBooks/DashHorizontalFeedBooks';
import './WritersHomeView.css';
import pancakeImage from '../../assets/images/menufour.jpeg';
import sexyWayneImage from '../../assets/images/sexyWayne.jpg'; 
import bonfire from '../../assets/images/bonfire.jpeg';
import { fetchHomeStats, resetHomeStats } from '../../store/homeSlice';
import { showGenericModal } from '../../store/modalSlice';
import { setSelectedTime } from '../../store/homeSlice';



const WritersHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = useSelector((state) => state.user.userId);
  const userName = useSelector((state) => state.user.userName);
  const { stats, isLoading, error } = useSelector((state) => state.home);
  const [retryCount, setRetryCount] = useState(0);
  const selectedTime = useSelector(state => state.home.selectedTime);

  const timeframeMapping = {
    '7days': 'Last 7 Days',
    '6weeks': 'Last 6 Weeks',
    '12months': 'Last 12 Months'
  };

  const supportsWebp = () => {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
  };

  useEffect(() => {
    // Only fetch if stats aren't loaded, not currently loading, and no error or limited retries
    console.log('questions');
    console.log('questions stats', stats);
    console.log('questions isLoading', isLoading);
    console.log('questions error', error);
    console.log('questions retryCount', retryCount);

    
    if (!stats && !isLoading  && retryCount < 3) {
      console.log('questions part two');
      dispatch(fetchHomeStats({ userId: userId, userName: userName }));
      setRetryCount(retryCount + 1); // Increment retry count
      console.log(stats);
    }
  }, [dispatch, stats, isLoading, error, retryCount]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount

  }, []); 

  const handleDelveIn = () => {
    navigate('/writers/delve-in-readers');
  };



  const piecesPublished = stats ? (selectedTime === '7days' ? stats['topline_numbers'] .seven_days_pieces : selectedTime === '6weeks' ? stats['topline_numbers'].six_weeks_pieces : stats['topline_numbers'].twelve_months_pieces) : 0;
  const pieceVisits = stats ? (selectedTime === '7days' ? stats['topline_numbers'].seven_days_pieceview : selectedTime === '6weeks' ? stats['topline_numbers'].six_weeks_pieceview : stats['topline_numbers'].twelve_months_pieceview) : 0;
  const piecesRead = stats ? (selectedTime === '7days' ? stats['topline_numbers'].seven_days_pieceread : selectedTime === '6weeks' ? stats['topline_numbers'].six_weeks_pieceread : stats['topline_numbers'].twelve_months_pieceread) : 0;
  const clicks = stats ? (selectedTime === '7days' ? stats['topline_numbers'].seven_days_affiliateclick : selectedTime === '6weeks' ? stats['topline_numbers'].six_weeks_affiliateclick : stats['topline_numbers'].twelve_months_affiliateclick) : 0;

    // Dummy data for feeds
    const pieceFeedData = {
      feedName: 'Most visited pieces',
      feedItems: [
        {
          imageSrc: sexyWayneImage,
          title: 'Sexy Dwayne',
        },

      ],
    };

    const bookFeedData = {
      feedName: 'Most clicked out to books',
      feedItems: [
        {
          imageSrc: bonfire,
          title: 'Bonfire of the Vanitiies',
        },
        {
          imageSrc: bonfire,
          title: 'Bonfire of the Vanitiies',
        },
        {
          imageSrc: bonfire,
          title: 'Bonfire of the Vanitiies',
        },
        {
          imageSrc: bonfire,
          title: 'Bonfire of the Vanitiies',
        },
      ],
    };

    const handlePress = () => {
      dispatch(showGenericModal({
        modalType: 'HOME_SETTINGS', // This could be 'EDIT_BIO' or any other modal type you have.
        modalProps: { // Assuming you want to pass some props based on the modal type
        }
      }));
    };

    const baseImageUrl = "https://shrtrstrz-coverart-dev.s3.amazonaws.com";

    const filteredPieces = stats ? stats.top_pieces.filter(piece => 
      piece.period === timeframeMapping[selectedTime] && (piece.image_jpeg || piece.image_webp)
    ).map(piece => ({
      ...piece,
      image_jpeg: piece.image_jpeg ? `${baseImageUrl}/${piece.image_jpeg}` : null,
      image_webp: piece.image_webp ? `${baseImageUrl}/${piece.image_webp}` : null
    })) : [];

    console.log('filteredPieces', filteredPieces);

    const webpSupported = supportsWebp();

    const filteredBooks = stats ? stats.top_books.filter(book => 
      book.period === timeframeMapping[selectedTime] 
    ).map(book => ({
      ...book
    })) : [];
    
  

 
  if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>; // Correct
 

  return (
    <div>
      <Header/>
      <div className="writers-home">
        
        <div className="writers-home-boxes">
          <div className="writers-home-title">Welcome to ShorterStories</div>
          <div className="writers-home-sub-title">First off, thank you:</div>
          <div className="writers-home-text">If you are reading this you are one of the first writers on ShorterStories. It is our honor.</div>
          <div className="writers-home-sub-title">Quick guide:</div>
          <div className="writers-home-text">You navigate using the footer. On the left is where you set up or edit your profile. On the right is where you publish a piece. On this tab, the home tab, you have performance statistics along with a way to log out.</div>
        </div>
        <div className="dashboardContainer">
          <div className="metricBlock">
            <h1 className='metricBoldDash'>{piecesPublished} </h1>
            <p className='metricDescription'>Pieces Published</p>
          </div>
          <div className="metricBlock">
            <h1 className='metricBoldDash'>{pieceVisits}</h1>
            <p className='metricDescription'>Pieces Visited</p>
          </div>
          <div className="imageBlock">
            <button onClick={handlePress} style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer' }}>
              <img className='pancakeroos' src={pancakeImage} alt="Pancake" style={{ width: '60px', height: '55px' }} />
            </button>
          </div>
          
        </div>
        <TwoAxisGraph timeframe={'7days'}  className='two-axis-chart-container'/>
        

        <div className="subtle-line"></div> 
        <DashHorizontalFeedPieces feedName='Most visited pieces' feedItems={filteredPieces.map(piece => ({
          imageSrc:  piece.image_jpeg,
          title: piece.title,
        }))} />
        <div className="subtle-line"></div> 
        <div className="dashboardContainerTwo">
          <div className="metricBlock">
            <h1 className='metricBoldDash'>{piecesRead} </h1>
            <p className='metricDescription'>Pieces Read</p>
          </div>
          <div className="metricBlock">
            <h1 className='metricBoldDash'>{clicks}</h1>
            <p className='metricDescription'>Clicks out to Retailer</p>
          </div>
        
          
        </div>
        <ThreeLinedGraph timeframe={'7days'} className='three-lined-chart-container'/>
        <button className="writers-home__delve-button" onClick={handleDelveIn}>
          Delve in to who's reading your writing
        </button>
        <div className="subtle-line"></div> 
        <DashHorizontalFeedBooks feedName={'Most clicked out to books'} feedItems={filteredBooks}  />
      </div>
        


    </div>
  );
};

export default WritersHome;
