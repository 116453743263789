import React from "react";
import { useNavigate } from "react-router-dom";
import './GatewayToHeaven.css';


function GatewayToHeaven() {


  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    navigate('/writers'); // Navigate to the feed page
  }

  
  return (
    <div className="gateway-box">
      <div className="gateway-content">
        <p className="gateway-text">For all the dreamers</p>
        <form onSubmit={handleSubmit}>
          <input className="gateway-button-login" type="submit" value="Writers' corner" />
        </form>
      </div>
    </div>
  );
}


export default GatewayToHeaven;