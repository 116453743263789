import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { checkEmployeeStatus } from '../../store/employeeSlice';
import EmployeeLoginForm from '../../components/EmployeeLoginForm/EmployeeLoginForm';
import NonEmployeePortal from '../../components/NonEmployeePortal/NonEmployeePortal';
import ReviewApplications from '../../components/ReviewApplications/ReviewApplications';
import EmailTool from '../../components/EmailTool/EmailTool';
import EmployeeLandingPage from '../EmployeedLandingPage/EmployeedLandingPage';
import './EmployeeDashboard.css';


function EmployeeDashboard() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const employeeRole = useSelector((state) => state.employee.employeeRole);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(checkEmployeeStatus());
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <EmployeeLoginForm />;
  }

  if (employeeRole !== 'cool') {
    return <NonEmployeePortal />;
  }

  return (
    <Routes>
      <Route path="/" element={<EmployeeLandingPage />} />
      <Route path="review-applications" element={<ReviewApplications />} />
      <Route path="email-tool" element={<EmailTool />} />
      <Route path="*" element={<Navigate to="/employee-dashboard" replace />} />
    </Routes>
  );
}

export default EmployeeDashboard;