import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenericModal } from '../../store/modalSlice';
import { setFilter, setShouldApplyFilters} from '../../store/theFeedSlice';
import './ModalFilterGenreTheFeed.css'; // Make sure to create this CSS file

const ModalFilterGenreTheFeed = () => {
  const dispatch = useDispatch();
  const currentGenres = useSelector(state => state.theFeed.genre);
  console.log(currentGenres);
  const [selectedGenres, setSelectedGenres] = useState(currentGenres);

  const genres = [
    "Fictional Autobiography", 
    "Creative Non-fiction",
    "Creative Fiction",
    "Reportage",
    "Memoir", 
    "Essay", 
    "Humor",
    "Science-Fiction", 
    "Fantasy", 
    "Horror", 
    "Romance", 
    "Thriller", 
    "Crime", 
    "Noir",
    "Erotica", 
    "Journalism",
    "Queer",
    "Activism and Social Change",
  ];

  const handleGenreSelect = (genre) => {
    setSelectedGenres(prevGenres => {
      if (prevGenres.includes(genre)) {
        return prevGenres.filter(g => g !== genre);
      } else {
        return [...prevGenres, genre];
      }
    });
  };

  const handleSave = () => {
    dispatch(setFilter({ filterType: 'genre', value: selectedGenres }));
    dispatch(setShouldApplyFilters(true));
    dispatch(hideGenericModal());
  };

  const handleClearAll = () => {
    setSelectedGenres([]);
  };

  const renderGenreItem = (genre) => {
    const isSelected = selectedGenres.includes(genre);
    return (
      <div 
        key={genre} 
        className={`genreItem ${isSelected ? 'selected' : ''}`} 
        onClick={() => handleGenreSelect(genre)}
      >
        <div className="label-container">
          {isSelected ? (
            <span className="genreText">&#8209;&#8209;&#8209;&gt;&#160;&#160;{genre}&#160;&#160;&lt;&#8209;&#8209;&#8209;</span>
          ) : (
            <span className="genreText">{genre}</span>
          )}
        </div>
      </div>
    );
  };


  return (
    <div className="modal-filter-genre">
      <div className="modalBackground">
        <div className="modalContainer">
          <h2 className="modalTitle">Filter by Genre</h2>
          <div className="genreList">
            {genres.map(renderGenreItem)}
          </div>
          <div 
            className={`genreItem ${selectedGenres.length === 0 ? 'selected' : ''}`} 
            onClick={handleClearAll}
          >
            <span className="genreText">Clear All</span>
          </div>
          <button className="infosButton" onClick={handleSave}>Apply Filters</button>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterGenreTheFeed;