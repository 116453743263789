import React from "react";
import { useNavigate } from "react-router-dom";
import './GatewayToLogin.css';


function GatewayToLogin() {


  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    navigate('/login'); // Navigate to the feed page
  }

  
  return (
    <div className="gateway-box-login">
      <div className="gateway-content-login">
        <p className="gateway-text-login">  To get extra stuff</p>
        <form onSubmit={handleSubmit}>
          <input className="gateway-login-button-login" type="submit" value="Create account/login" />
        </form>
      </div>
    </div>
  );
}


export default GatewayToLogin;