import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { apiInstance } from '../../utils/axiosInstance';
import Header from '../../components/Header/Header';
import PieceWords from '../../components/PieceWords/PieceWords';
import Footer from '../../components/Footer/Footer';
import './Piece.css';
import { getCurrentUser } from 'aws-amplify/auth';
import BooksInPiece from '../../components/BooksInPiece/BooksInPiece'
import { setUserId } from '../../store/userSlice'; 
import { useLocation } from 'react-router-dom';
import printingPressProfile from '../../assets/images/printingpressprofile.png';


function Piece()  {
  const dispatch = useDispatch();
  const { pieceHash } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const pieceDataFromFeed = location.state?.pieceData;
  const prevRoute = location.state?.prevRoute || null;
  const pieceViewHashFromFeed = location.state?.pieceViewHash;
  const [pieceViewHash, setpieceViewHash] = useState(pieceViewHashFromFeed || null);
  const [piece, setPiece] = useState(pieceDataFromFeed || null);
  const navigate = useNavigate();
  const userId = useSelector(state => state.user.userId);
  console.log('user ids piece', piece);

  const [supportsWebp, setSupportsWebp] = useState(false);
  const [maxScroll, setMaxScroll] = useState(0); 
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  useEffect(() => {
    const checkAndSetUserId = async () => {
      if (!userId) {
        try {
          const user = await getCurrentUser();
          if (user && user.attributes && user.attributes.sub) {
            dispatch(setUserId(user.attributes.sub));

            console.log('Retrieved and set userId:', user.attributes.sub);
          }
        } catch (error) {
          console.log('No authenticated user found');
        }
      }
    };

    checkAndSetUserId();
  }, []); 


  useEffect(() => {
    const updateMaxScroll = () => {
      const scrolled = window.scrollY;
      const scrollTotal = document.documentElement.scrollHeight - window.innerHeight;
      if (!hasReachedEnd && window.scrollY >= scrollTotal - 2) {
        console.log('User has scrolled to the end of the document');
        setHasReachedEnd(true);
      }
    };
  
    window.addEventListener('scroll', updateMaxScroll);
  
    return () => {
      window.removeEventListener('scroll', updateMaxScroll);
    };
  }, [hasReachedEnd]);  // Adding hasReachedEnd as a dependency
  



  function supportersWebp() {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
  }

  useEffect(() => {
    setSupportsWebp(supportersWebp());
  }, []);

  const handleImageLoad = (e) => {


    const img = e.target;
    if (img.naturalWidth * 1 < img.naturalHeight) {

        // Adjust the image height to match its width for landscape images
        img.style.width = '100%'; // Set the width to 40% of its container
        img.style.height = `${img.offsetWidth * 1}px`;  // Match the height to the current width
        img.style.objectFit = 'cover'; // Ensure the image covers the area, cropping as necessary
    } else {

        // Apply or reset styles for portrait/square images as necessary
        img.style.width = '100%'; // Maintain the 40% width
        img.style.height = 'auto'; // Allow the height to adjust naturally
    }
};


console.log('Piece hash:', piece);
  


  useEffect(() => {
    if (!piece) {
      apiInstance.get(`/pieces/fetch/${pieceHash}`)
        .then(response => {
          setPiece(response.data);
          console.log('Piece:', response.data);
  
          // Generate the hash here, inside the .then block
          const generateHash = (length) => {
            return crypto.getRandomValues(new Uint8Array(length))
              .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
              .slice(0, length);
          };
          const pieceViewHash = generateHash(12);
  
          const pieceViewData = {
            piece_view_hash: pieceViewHash,
            piece_id: response.data.piece.piece_id,
            piece_hash: response.data.piece.piece_hash,
            referrer_page: prevRoute,
            consuming_user: userId,
            referrer_external: document.referrer,
            piece_writer_name: response.data.author_info.name,
            profile_hash: response.data.author_info.profile_hash,
            publishing_user: response.data.piece.publishing_user,
          };
  
          console.log('Piece view data:', pieceViewData);
  
          // Post the piece view data here, also inside the .then block
          apiInstance.post('/clicks/pieceview/create', pieceViewData)
            .then(res => console.log('Piece view logged successfully', res))
            .catch(error => console.error('Error logging piece view:', error));
        })
        .catch(error => {
          console.error('Error fetching piece:', error);
        });
    }
  }, [piece, pieceHash]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPieceData = async () => {
      setIsLoading(true);
      try {
        const response = await apiInstance.get(`/pieces/fetch/${pieceHash}`);
        setPiece(response.data);

      } catch (error) {
        console.error('Error fetching piece:', error);
      } finally {
        setIsLoading(false);
      }
    };

    window.scrollTo(0, 0);
    

    

  
    fetchPieceData();

    setHasReachedEnd(false);


    
  }, [pieceHash]);

    // Add another useEffect to react to changes in hasReachedEnd
    useEffect(() => {
      if (hasReachedEnd && piece) {
        console.log('hasReachedEnd is now true');  // This logs when hasReachedEnd is true
        console.log('Piece:', piece.piece);  // This logs the piece data
    
        // Prepare the data using the existing pieceViewHash
        const pieceReadData = {
          piece_read_hash: pieceViewHash,  // Reusing the hash generated for the view
          piece_id: piece.piece.piece_id,
          piece_hash: piece.piece.piece_hash,
          piece_title: piece.piece.title, // New field
          piece_image_jpeg: piece.piece.piece_image_jpeg, // New field
          piece_image_webp: piece.piece.piece_image_webp, // New field
          serial_first_profile_hash: piece.piece.serial_first_profile_hash, // New field
          serial_part_number: piece.piece.serial_part_number, // New field
          referrer_page: prevRoute,
          consuming_user: userId,
          referrer_external: document.referrer,
          piece_writer_name: piece.author_info.name,
          profile_hash: piece.author_info.profile_hash,
          publishing_user: piece.piece.publishing_user,
        };
    
        // Post the piece read data to the server
        apiInstance.post('/clicks/pieceread/create', pieceReadData)
          .then(response => console.log('Piece read logged successfully', response))
          .catch(error => console.error('Error logging piece read:', error));
      }
    }, [hasReachedEnd, piece, prevRoute, userId, pieceViewHash]);  // Ensure pieceViewHash is included in the dependencies
    
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!piece) {
    return <div>Loading...</div>;
  }

  console.log(piece);

  const getLengthCategory = (wordCount) => {

    if (wordCount <= 250) return 'Bite Sized';
    if (wordCount <= 1500) return 'Flash';
    if (wordCount <= 5000) return 'Shorter Story';
    return 'Short Story';
  };

  const handleAuthorClick = (piece) => {
    navigate(`/writer/${piece.author_info.profile_hash}`, { state: { authorInfo: piece.author_info } });
  };

  


  const goToNextPiece = () => {
  window.scrollTo(0, 0);


  const nextPieceHash = piece.piece.next_serial_hash;
  const currentPieceHash = pieceHash;

  navigate(`/piece/${nextPieceHash}`, { 
    state: { 
      prevRoute: `/piece/${currentPieceHash}`
    } 
  });
  console.log('user id:', userId);

  apiInstance.get(`/pieces/fetch/${nextPieceHash}`)
    .then(response => {
      setPiece(response.data);

      console.log('Next Piece:', response.data);

      // Generate the hash
      const generateHash = (length) => {
        return crypto.getRandomValues(new Uint8Array(length))
          .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
          .slice(0, length);
      };
      const newPieceViewHash = generateHash(12);
      setpieceViewHash(newPieceViewHash);

      const pieceViewData = {
        piece_view_hash: newPieceViewHash,
        piece_id: response.data.piece.piece_id,
        piece_hash: response.data.piece.piece_hash,
        referrer_page: `/piece/${currentPieceHash}`,
        consuming_user: userId,
        referrer_external: '',  // It's internal navigation
        piece_writer_name: response.data.author_info.name,
        profile_hash: response.data.author_info.profile_hash,
        publishing_user: response.data.piece.publishing_user,
      };

      apiInstance.post('/clicks/pieceview/create', pieceViewData)
      .then(res => console.log('Next piece view logged successfully', res))
      .catch(error => console.error('Error logging next piece view:', error));

    // Reset scroll position and hasReachedEnd
    window.scrollTo(0, 0);
    setHasReachedEnd(false);

    
  }).catch(error => {
    console.error('Error fetching next piece:', error);
  });


  };

  const goToPreviousPiece = () => {
    window.scrollTo(0, 0);
  
    const previousPieceHash = piece.piece.previous_serial_hash;
    const currentPieceHash = pieceHash;
  
    navigate(`/piece/${previousPieceHash}`, { 
      state: { 
        prevRoute: `/piece/${currentPieceHash}`
      } 
    });
    console.log('user id:', userId);
  
    apiInstance.get(`/pieces/fetch/${previousPieceHash}`)
      .then(response => {
        setPiece(response.data);
  
        console.log('Previous Piece:', response.data);
  
        // Generate the hash
        const generateHash = (length) => {
          return crypto.getRandomValues(new Uint8Array(length))
            .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
            .slice(0, length);
        };
        const newPieceViewHash = generateHash(12);
        setpieceViewHash(newPieceViewHash);
  
        const pieceViewData = {
          piece_view_hash: newPieceViewHash,
          piece_id: response.data.piece.piece_id,
          piece_hash: response.data.piece.piece_hash,
          referrer_page: `/piece/${currentPieceHash}`,
          consuming_user: userId,
          referrer_external: '',  // It's internal navigation
          piece_writer_name: response.data.author_info.name,
          profile_hash: response.data.author_info.profile_hash,
          publishing_user: response.data.piece.publishing_user,
        };
  
        apiInstance.post('/clicks/pieceview/create', pieceViewData)
        .then(res => console.log('Previous piece view logged successfully', res))
        .catch(error => console.error('Error logging previous piece view:', error));
  
      // Reset scroll position and hasReachedEnd
      window.scrollTo(0, 0);
      setHasReachedEnd(false);
    }).catch(error => {
      console.error('Error fetching previous piece:', error);
    });
  
    window.scrollTo(0, 0);
    setHasReachedEnd(false);
  };




  return (
    <div className="piecepage">
      <Header />
      <div className="contents-container-piece"> {/* New container for content */}
        <div className="piece-author-info">
          <div className='piece-author-only' onClick={() => handleAuthorClick(piece)} style={{ cursor: 'pointer' }}>
            <img src={piece.author_info.profile_image_jpeg||piece.author_info.vertical_image_jpeg||printingPressProfile} alt="Author" className="piece-author-photo" />
            <span className="piece-author-name">{piece.author_info.name}</span>
          </div>
          <span className="preview-length-indicator">{getLengthCategory(piece.piece.piece_length)}</span>
        </div>
        <div className="piece-image-container">
        {supportsWebp && piece.piece.piece_image_webp ? (
          <img src={piece.piece.piece_image_webp} alt="Piece" className="piece-image" onLoad={handleImageLoad}/>
        ) : piece.piece.piece_image_jpeg ? (
          <img src={piece.piece.piece_image_jpeg} alt="Piece" className="piece-image" onLoad={handleImageLoad}/>
        ) : (
          piece.piece.image && <img src={piece.piece.image} alt="Piece" className="piece-image" onLoad={handleImageLoad}/>
        )}
        </div>
        <div className="genre-style-container-piece">
          <span className="genre-style-text-piece">
            Genre: <span className="italic-text">{piece.piece.piece_genre}</span>
          </span>
          <span className="genre-style-text-piece">
            Style: <span className="italic-text">{piece.piece.piece_style}</span>
          </span>
        </div>
        <div className="divider-piece"></div>
        {piece.links && piece.links.length > 0 && (
          <>
            <div className="promoted-books-text">Promoted books</div>
            <BooksInPiece feedItems={piece.links} prevRoute={prevRoute} piece={piece.piece} author_info={piece.author_info} pieceViewHash={pieceViewHash}/>
            
            <div className="divider-piece"></div>
          </>
        )}
        <div className="piecepage-words-piece">
          <PieceWords className="piecewords"
            title={piece.piece.title}
            authorName={piece.piece.author_name}
            content={piece.piece.piece_content}
            isSerial={piece.piece.is_serial}
            serialPartNumber={piece.piece.serial_part_number}
            previousSerialHash={piece.piece.previous_serial_hash}
            nextSerialHash={piece.piece.next_serial_hash}
            onNextClick={goToNextPiece}
            onPreviousClick={goToPreviousPiece}
          />
        </div>
        {piece.piece.is_serial && piece.piece.next_serial_hash && piece.piece.piece_length > 250 && (
          <div className="serial-navigation">
            <div className="serial-nav-link next" onClick={goToNextPiece}> 
              <span>
                <span className="main-text">Next piece</span>
                <span className="arrow">&#8594;</span>
                <br />
                <span className="sub-text">in series</span>
              </span>
            </div>
          </div>
        )}
        
        {piece.links && piece.links.length > 0 && piece.piece.piece_length > 250 && (
          <>
            <div className="divider-piece"></div>
            <div className="promoted-books-text">Promoted books</div>
            <BooksInPiece feedItems={piece.links} prevRoute={prevRoute} piece={piece.piece} author_info={piece.author_info} pieceViewHash={pieceViewHash}/>
            
            <div className="divider-piece"></div>
          </>
        )}
      </div>
    </div>
  );
}

export default Piece;