import React, { useState, useEffect }  from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './HeaderPublishPreview.css';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { fetchAuthSession } from 'aws-amplify/auth';
import { apiInstance } from '../../utils/axiosInstance';
import {resetPublishingProcess} from '../../store/publishingSlice';
import {clearSelections} from '../../store/taggingSlice';

import defaultImage from '../../assets/images/birdie.jpeg';



function HeaderPublishPreview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  
  const [hasChanges, setHasChanges] = useState(false);
  const userId = useSelector(state => state.user.userId);
  const userName = useSelector(state => state.user.userName);
  const currentPhoto = useSelector(state => state.publishing.currentPhoto) || defaultImage;
  const { data: authorProfile } = useSelector((state) => state.authorProfile);
  const [isPublishing, setIsPublishing] = useState(false);

  const {
    title,
    piece,
    taggedBooks,
    description,

    genre,
    style,
    isSerial,
    serialFirstProfileHash,
    serialPartNumber,
    previousSerialHash

  } = useSelector(state => state.publishing);

  console.log('title', taggedBooks);
  console.log('authorProfile', authorProfile);
  console.log('userName', userName);
  console.log('userId', userId);

  function stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  function countWords(text) {
    // Use regular expression to replace multiple spaces with a single space and trim spaces
    return text.replace(/\s+/g, ' ').trim().split(' ').length;
  }

  const wordCount = countWords(stripHtml(piece));


  const { mutate: publishPiece, isLoading, isError } = useMutation(async (formData) => {
    const session = await fetchAuthSession();
    const jwtToken = session.tokens.idToken.toString();

    console.log('formData', formData);

    const response = await apiInstance.post('/pieces/create', formData, {
        headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
}, {
    onSuccess: (data) => {
        console.log('Piece published successfully:', data);
        queryClient.invalidateQueries(['pieces']);
        dispatch(clearSelections()); // Assuming you have imported the slice as taggingSlice
        dispatch(resetPublishingProcess());
        navigate('/writers');
        setIsPublishing(false);
    },
    onError: (error) => {
        console.error('Error publishing piece:', error);
        alert('Failed to publish the piece. Please try again.');
        alert(error);
        setIsPublishing(false);
        
    },
});

  const navigateToProfile = () => {
    navigate('/writers/publish/details'); // Assuming '/profile' is the route for the profile page
  };

  function resizeImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
        const img = document.createElement('img');
        const reader = new FileReader();
        
        reader.onload = function (e) {
            img.src = e.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(blob => {
                    resolve(new File([blob], file.name, { type: file.type }));
                }, file.type, 1);
            };
        };
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
}

const saveChanges = async () => {
  try {
  setIsPublishing(true);
  const formData = new FormData();
  formData.append('title', title);
  formData.append('piece_content', piece);
  formData.append('piece_genre', genre);
  formData.append('piece_style', style);
  formData.append('taggedBooks', JSON.stringify(taggedBooks));
  formData.append('userId', userId);
  formData.append('profile_hash', authorProfile.profile_hash);
  formData.append('author_name', authorProfile.name);
  formData.append('publishing_user', userId);
  formData.append('description', description);
  formData.append('piece_length', wordCount);
  const showInFeed = authorProfile.profile_hash === 'AcS1Na49' ? 'false' : 'true';
  formData.append('show_in_the_feed', showInFeed);
  if (isSerial) {
    formData.append('is_serial', 'true');
    formData.append('serial_first_profile_hash', serialFirstProfileHash);
    formData.append('serial_part_number', serialPartNumber.toString());
    formData.append('previous_serial_hash', previousSerialHash);

  }

  console.log('FormData initialized:', formData);

  if (currentPhoto) {
            try {
                const response = await fetch(currentPhoto);
                const blob = await response.blob();

                console.log('Blob created:', blob);

                const mimeType = blob.type;
                const fileExtension = mimeType.split('/')[1];
                const filename = `uploaded_image.${fileExtension}`;
                const originalFile = new File([blob], filename, { type: mimeType });

                console.log('Original file size:', originalFile.size);

                // Resize the image
                const resizedFile = await resizeImage(originalFile, 2000, 2000);
                console.log('Resized file size:', resizedFile.size);

                // Check the size of the resized file
                if (resizedFile.size > 10 * 1024 * 1024) { // 10MB limit
                    alert('Resized file size still exceeds the maximum limit of 10MB. Please upload a smaller file.');
                    setIsPublishing(false);
                    return;
                }

                formData.append('image', resizedFile);
                console.log('File appended to FormData:', resizedFile);
            } catch (error) {
                console.error('Error processing image:', error);
                alert('Error processing image. Please try again.');
                setIsPublishing(false);
                return; // Exit the function to prevent further execution
            }
        }

        // Proceed to publish the piece
        await publishPiece(formData);
    } catch (error) {
        console.error('Error in saveChanges:', error);
        alert(`Error processing image: ${error.message || error}. Please try again or contact Fred if problem persists.`);
        setIsPublishing(false);
    }
};



  return (
    <div className="header-preview-piece-container"> {/* New container div */}
      <div className="header-preview-piece">
        <span className="return-to-edit" onClick={navigateToProfile}>Return to edit</span>
        <span 
          className={`publish-piece ${isPublishing ? 'disabled' : ''}`} 
          onClick={!isPublishing ? saveChanges : undefined}
        >
          {isPublishing ? 'Publishing...' : 'Publish piece'}
        </span>
      </div>
    </div>
  );

}

export default HeaderPublishPreview;