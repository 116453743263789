import React, { useState } from "react";
import { apiInstance } from "../../utils/axiosInstance"; // Import your Axios instance
import { fetchAuthSession } from 'aws-amplify/auth'; // For handling auth
import './EmailTool.css';

const EmailTool = () => {
  const [isWriter, setIsWriter] = useState(null);
  const [isPublishedWriter, setIsPublishedWriter] = useState(null);
  const [isReader, setIsReader] = useState(null);
  const [isInternal, setIsInternal] = useState(null);
  const [isTester, setIsTester] = useState(null);
  const [s3FileLocation, setS3FileLocation] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [isTesting, setIsTesting] = useState(true); // Add is_testing state
  const [alertMessage, setAlertMessage] = useState("");
  const [logicType, setLogicType] = useState("AND"); // Default to "AND"

  const handleSubmit = async () => {
    try {
      // If it's not a test email, show confirmation dialog
      if (!isTesting) {
        const confirmation = window.confirm("Are you sure you want to send a non-testing email?");
        if (!confirmation) return;
      }

      // Fetch the JWT token from AWS Amplify
      const session = await fetchAuthSession();
      const jwtToken = session.tokens.idToken.toString();

      // Prepare the segments object
      const segments = {
        is_writer: isWriter,
        is_published_writer: isPublishedWriter,
        is_reader: isReader,
        is_internal: isInternal,
        is_tester: isTester,
      };

      // Prepare the data for the API call
      const data = {
        segments,
        subject: emailSubject,
        s3FileLocation: s3FileLocation,
        logic_type: logicType,  // Add logic type here
        is_testing: isTesting,  // Send the is_testing flag
      };

      // Send the POST request to the API
      const response = await apiInstance.post("/email/emailtool", data, {
        headers: { Authorization: `Bearer ${jwtToken}` }
      });

      // Handle the response
      setAlertMessage("Email sent successfully!");

    } catch (error) {
      console.error("Error sending email:", error);
      setAlertMessage("Failed to send email. Please try again.");
    }
  };

  const handleSelectChange = (setFunction) => (event) => {
    const value = event.target.value === "none" ? null : event.target.value === "true";
    setFunction(value);
  };

  return (
    <div className="email-tool-container">
      <h1>Email Tool</h1>

      <div className="segment-filters">
        <label>
          Is Writer:
          <select onChange={handleSelectChange(setIsWriter)} defaultValue="none">
            <option value="none">None</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </label>

        <label>
          Is Published Writer:
          <select onChange={handleSelectChange(setIsPublishedWriter)} defaultValue="none">
            <option value="none">None</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </label>

        <label>
          Is Reader:
          <select onChange={handleSelectChange(setIsReader)} defaultValue="none">
            <option value="none">None</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </label>

        <label>
          Is Internal (Agent):
          <select onChange={handleSelectChange(setIsInternal)} defaultValue="none">
            <option value="none">None</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </label>

        <label>
          Is Tester:
          <select onChange={handleSelectChange(setIsTester)} defaultValue="none">
            <option value="none">None</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </label>

        <label>
          S3 File Location:
          <input
            type="text"
            value={s3FileLocation}
            onChange={(e) => setS3FileLocation(e.target.value)}
            placeholder="Enter S3 file path"
          />
        </label>

        <label>
          Email Subject:
          <input
            type="text"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            placeholder="Enter Email Subject"
          />
        </label>

        <label>
          Combine conditions with:
          <select value={logicType} onChange={(e) => setLogicType(e.target.value)}>
            <option value="AND">AND</option>
            <option value="OR">OR</option>
          </select>
        </label>

        <label>
          Is this a test email?
          <input
            type="checkbox"
            checked={isTesting}
            onChange={(e) => setIsTesting(e.target.checked)}
          />
        </label>
      </div>

      <button onClick={handleSubmit}>Send Email</button>

      {alertMessage && <div className="alert-message">{alertMessage}</div>}
    </div>
  );
};

export default EmailTool;
