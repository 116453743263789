import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import './ProfileFeed.css';
import printingPressProfile from '../../assets/images/printingpressprofile-full.png';
import usePreviousRoute from '../../hooks/usePreviousRoute';
import { apiInstance } from '../../utils/axiosInstance';
import closeIcon from '../../assets/images/circle-xmark-regular.svg'; 
import { fetchAuthSession } from 'aws-amplify/auth';


const ProfileFeed = ({ pieces: initialPieces, authorProfile }) => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const prevRoute = usePreviousRoute();
  const userId = useSelector(state => state.user.userId);
  const [pieces, setPieces] = useState(initialPieces);

  

  console.log('Current location:', location.pathname); 

  const handleDeletePiece = async (pieceHash) => {
    if(window.confirm("Are you sure you want to delete this piece? This action cannot be undone.")) {
      console.log("Attempting to delete piece:", pieceHash);
  
      const session = await fetchAuthSession();
      const jwtToken = session.tokens.idToken.toString();

  
      const deleteUrl = `pieces/delete_piece/${pieceHash}`;
  
      const config = {
        headers: {
          'Authorization': `Bearer ${jwtToken}`
        }
      };
  
      apiInstance.patch(deleteUrl, {}, config)
        .then(response => {
          console.log('Piece marked as deleted successfully:', response.data);
          const updatedPieces = pieces.filter(piece => piece.piece.piece_hash !== pieceHash);
          setPieces(updatedPieces);
          console.log('Piece marked',pieces);
        })
        .catch(error => {
          console.error('Error marking piece as deleted:', error);
        });
    }
  };
  
  


  const [supportsWebp, setSupportsWebp] = useState(false);

  function supportersWebp() {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
  }

  useEffect(() => {
    setSupportsWebp(supportersWebp());
  }, []);

  


  const goToPiecePage = (item) => {
    const pieceDataWithAuthor = {
      ...item,
      author_info: authorProfile // Assuming authorProfile is structured appropriately
    };
    const generateHash = (length) => {
      return crypto.getRandomValues(new Uint8Array(length))
        .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')  // Base 36 encoding
        .slice(0, length);  // Ensure the hash is exactly 'length' characters
    };

    const pieceViewHash = generateHash(12); 


    
    const pieceViewData = {
      piece_view_hash: pieceViewHash,
      piece_id: item.piece.piece_id,
      piece_hash: item.piece.piece_hash,
      referrer_page: prevRoute,
      consuming_user: userId,
      referrer_external: document.referrer,
      piece_writer_name: item.author_info.name,
      profile_hash: item.author_info.profile_hash,
      publishing_user: item.piece.publishing_user,
    }; 

    apiInstance.post('/clicks/pieceview/create', pieceViewData)
    .then(response => console.log('Piece view logged successfully', response))
    .catch(error => console.error('Error logging piece view:', error));


    navigate(`/piece/${item.piece.piece_hash}`, { state: { pieceData: item, prevRoute: prevRoute, pieceViewHash: pieceViewHash } });
    // navigate(`/piece/${item.piece.piece_hash}`, { state: { pieceData: item } });
  };

  const handleAuthorClick = (item) => {

    window.scrollTo(0, 0);

     
  };

  const getLengthCategory = (length) => {
    if (length <= 250) return 'Bite Sized';
    if (length <= 1500) return 'Flash';
    if (length <= 5000) return 'Shorter Story';
    return 'Short Story';
  };

  const handleImageLoad = (e) => {


    const img = e.target;
    if (img.naturalWidth * 1 < img.naturalHeight) {

        // Adjust the image height to match its width for landscape images
        img.style.width = '100%'; // Set the width to 40% of its container
        img.style.height = `${img.offsetWidth * 1}px`;  // Match the height to the current width
        img.style.objectFit = 'cover'; // Ensure the image covers the area, cropping as necessary
    } else {

        // Apply or reset styles for portrait/square images as necessary
        img.style.width = '100%'; // Maintain the 40% width
        img.style.height = 'auto'; // Allow the height to adjust naturally
    }
};

const numberToWord = (num) => {
  const words = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  return num >= 1 && num <= 9 ? words[num - 1] : num.toString();
};

  return (
    <div className="profileFeed-contentContainer">
      {pieces.map((item, index) => (
        <div key={index} className="profileFeed-feedItem">
          <div className="profileFeed-authorAndLengthInfo">
            <div className="profileFeed-authorInfo" onClick={() => handleAuthorClick(item)} style={{ cursor: 'pointer' }}>
              <picture>
              {authorProfile.profile_image_webp && (
                  <source srcSet={authorProfile.profile_image_webp} type="image/webp"/>
              )}
              {authorProfile.profile_image_jpeg && (
                  <source srcSet={authorProfile.profile_image_jpeg} type="image/webp"/>
              )}
              <img src={authorProfile.profile_image_webp || authorProfile.vertical_image_jpeg || printingPressProfile} 
                  alt={item.piece.name}
                  className="profileFeed-authorPhoto"
              />
              </picture>
              <span className="profileFeed-authorName">{authorProfile.name}</span>
            </div>

            {location.pathname === "/writers/profile" && userId === item.piece.publishing_user ? (
              <img 
                src={closeIcon} 
                alt="Delete"
                className="profileFeed-deleteIcon"
                onClick={() => handleDeletePiece(item.piece.piece_hash)}
              />
            ) : (
              <span className="profileFeed-lengthIndicator">{getLengthCategory(item.piece.piece_length)}</span>
            )}
          </div>
          {supportsWebp && item.piece.piece_image_webp ? (
            <img src={item.piece.piece_image_webp} alt="Piece" className="profileFeed-pieceImage" onLoad={handleImageLoad}/>
          ) : item.piece.piece_image_jpeg ? (
            <img src={item.piece.piece_image_jpeg} alt="Piece" className="profileFeed-pieceImage" onLoad={handleImageLoad}/>
          ) : (
            item.piece.image && <img src={item.piece.image} alt="Piece" className="profileFeed-pieceImage" onLoad={handleImageLoad}/>
          )}

          <div className="profileFeed-pieceInfo">
            <div className="profileFeed-title-and-button-container">
              <h3>
                  {item.piece.title}
                  {item.piece.is_serial && item.piece.serial_part_number && (
                    <span className="profileFeed-serial-part">
                      {" (part " + numberToWord(item.piece.serial_part_number) + ")"}
                    </span>
                  )}
              </h3>
              <button className="profileFeed-read-piece-button" onClick={() => goToPiecePage(item)}>Read</button>
            </div>
            <p>{item.piece.description}</p>
            <div className="profileFeed-genre-style-container">
              <span className="profileFeed-genre-style-text">Genre: <span className="italic-text">{item.piece.piece_genre}</span></span>
              <span className="profileFeed-genre-style-text">Style: <span className="italic-text">{item.piece.piece_style}</span></span>
            </div>
          </div>
          <hr className="profileFeed-divider" />
        </div>
      ))}
    </div>
  );
};

export default ProfileFeed;
